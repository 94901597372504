import React, { useContext, ReactNode } from "react";
// Styles
import "./styles/styles.scss";
// Icons
import LoadingIcon from "@icons/LoadingIcon.svg";
// Context
import { Context } from "../../context";

interface IProps {
   children: ReactNode;
}

const Content: React.FC<IProps> = ({ children }) => {
   const { isLoading, isSearchEnter } = useContext(Context);
   return (
      <span className="primary-button__content">
         {isLoading && !isSearchEnter ? (
            <LoadingIcon className="primary-button__content__loading-icon" />
         ) : (
            children
         )}
      </span>
   );
};

export default React.memo(Content);
