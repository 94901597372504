export const bannerRequestType = {
   GET_BANNER: "GET_BANNER",
} as const;

export type TReqType = (typeof bannerRequestType)[keyof typeof bannerRequestType];

export const stateType = {
   banner: "banner",
} as const;

export type TStateType = (typeof stateType)[keyof typeof stateType];
