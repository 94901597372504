import React from "react";
// Components
import TextInputField from "@components/TextInputField";
// Icons
import SearchIcon from "@icons/SearchIcon.svg";
// React hook form
import { useForm, Controller } from "react-hook-form";
// Utils
import { formSubmitOnEnter } from "@utils/formSubmitOnEnter";
// Router
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

type TFormData = {
   search: string;
};

const SearchInputForm: React.FC = () => {
   // Router methods
   const navigate = useNavigate();
   const location = useLocation();
   const [searchParams] = useSearchParams();
   // Hook form
   const { control, handleSubmit } = useForm<TFormData>({
      defaultValues: {
         search: "",
      },
   });

   const handleSearch = (data: { search?: string }): void => {
      if (data?.search) {
         const searched = encodeURIComponent(data.search);
         let res = "";

         if (location.pathname.includes("/search")) {
            res = `${searched}?${searchParams.toString()}`;
         } else {
            res = searched;
         }

         navigate(`/search/${res}`);
      }
   };

   return (
      <form {...formSubmitOnEnter(handleSubmit(handleSearch))}>
         <Controller
            name="search"
            control={control}
            render={({ field }) => (
               <TextInputField
                  label=""
                  placeholder="Search for a product"
                  size="fluid"
                  rightIcon={SearchIcon}
                  rightIconOnClick={handleSubmit(handleSearch)}
                  {...field}
               />
            )}
         />
      </form>
   );
};

export default React.memo(SearchInputForm);
