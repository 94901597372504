import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
// Types
import { TPayload } from "./types";
// Handlers
import { handleGetMatches } from "../handlers";
import { matchesRejected, matchesResolved } from "../../sliceOld";
import { AnyAction } from "@reduxjs/toolkit";
// Utils
import { matchesRequestType as reqType } from "@features/Matches/utils/constants";

function* getMatches({ branchId, page, perPage, currency }: TPayload & AnyAction) {
   try {
      const res: Awaited<PromiseLike<ReturnType<typeof handleGetMatches>>> = yield call(
         handleGetMatches,
         { branchId, page, perPage, currency }
      );
      yield put(matchesResolved(res));
   } catch (err) {
      yield put(matchesRejected(err));
   }
}

export default function* (): unknown {
   // Request's
   yield takeEvery("matchesOld/matchesRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLeading(reqType.GET_MATCHES_OLD, getMatches);
}
