import {
   call,
   put,
   takeEvery,
   takeLatest,
   SagaReturnType,
   takeLeading,
} from "redux-saga/effects";
// Handlers
import {
   handleDeleteList,
   handleGetProductList,
   handlePushProductIntoList,
   handleRemoveProductFromList,
   handleSaveList,
} from "../handlers";
// Actions
import { productListRejected, productListResolved } from "../../slice/index";
// Utils
import { TProductListPayload } from "./types";
import { productListRequestType as reqType } from "../../../utils/constants";
// Types
import { AnyAction } from "@reduxjs/toolkit";

function* getProductList({ page }: TProductListPayload): unknown {
   try {
      const result: SagaReturnType<typeof handleGetProductList> = yield call(
         handleGetProductList,
         {
            page,
         }
      );
      yield put(productListResolved(result));
   } catch (err) {
      yield put(productListRejected(err));
   }
}

function* deleteProductList(): unknown {
   try {
      const result: SagaReturnType<typeof handleDeleteList> = yield call(
         handleDeleteList
      );
      yield put(productListResolved(result));
   } catch (err) {
      yield put(productListRejected(err));
   }
}

function* pushProductIntoList({ pid, total }: AnyAction | TProductListPayload): unknown {
   try {
      const result: SagaReturnType<typeof pushProductIntoList> = yield call(
         handlePushProductIntoList,
         {
            pid,
            total,
         }
      );
      yield put(productListResolved(result));
   } catch (err) {
      yield put(productListRejected(err));
   }
}

function* removeProductFromList({ pid }: AnyAction | TProductListPayload): unknown {
   try {
      const result: SagaReturnType<typeof handleRemoveProductFromList> = yield call(
         handleRemoveProductFromList,
         { pid }
      );
      yield put(productListResolved(result));
   } catch (err) {
      yield put(productListRejected(err));
   }
}

function* saveList({ listName }: TProductListPayload): unknown {
   try {
      const result: SagaReturnType<typeof handleSaveList> = yield call(handleSaveList, {
         listName,
      });
      yield put(productListResolved(result));
   } catch (err) {
      yield put(productListRejected(err));
   }
}

export default function* (): unknown {
   // Request's
   yield takeEvery("productList/productListRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLeading(reqType.GET_LIST_PRODUCTS, getProductList);
   yield takeLatest(reqType.DELETE_LIST_PRODUCTS, deleteProductList);
   yield takeLatest(reqType.PUSH_PRODUCTS_INTO_LIST, pushProductIntoList);
   yield takeLatest(reqType.SAVE_PRODUCTS_LIST, saveList);
   yield takeEvery(reqType.REMOVE_PRODUCT_FROM_LIST, removeProductFromList);
}
