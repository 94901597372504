import React, { ReactNode, useEffect } from "react";
// Styles
import "./styles/main.scss";
// Icons
import CloseIcon from "@icons/CloseIcon.svg";

export interface IProps {
   /**
    * To show/hide the modal.
    * In custom hooks you can use the useToggle custom hook to toggle the modal.
    */
   isOpen: boolean;
   /**
    * Handler toggler will be from the useToggle custom hook so just pass that so that
    * we can toggle the modal when the background is clicked.
    */
   handleToggler?(): void;
   /**
    * You need to wrap your elements inside the modal to make it as a content of the modal.
    */
   children?: ReactNode;
   /**
    * To expand the modal.
    */
   isExpanded?: boolean;
   /**
    * To further customize the modal you can pass a className.
    */
   className?: string;
   /**
    * To add the close button into the top right
    */
   withCloseButton?: boolean;
}

const Modal = ({
   isOpen = false,
   handleToggler = () => null,
   children,
   isExpanded = false,
   className = "",
   withCloseButton = false,
}: IProps) => {
   // Prevent scroll on body when modal is open
   useEffect(() => {
      document.body.style.overflowY = isOpen ? "hidden" : "auto";

      // When modal is unmounted make it scrollable again
      return () => {
         document.body.style.overflowY = "auto";
      };
   }, [isOpen]);

   const handleEscape = (e: KeyboardEvent) => {
      if (e.keyCode === 27 && isOpen) handleToggler();
   };

   useEffect(() => {
      /**
       * As we do not want the user to click or focus on the element
       * to invoke the "handleEscape" function we will use window listener
       * because when we add it to the modal div directly the user
       * needs to click or to focus on the element so that the function will invoke.
       */
      window.addEventListener("keyup", handleEscape);

      return () => {
         window.removeEventListener("keyup", handleEscape);
      };
   }, [isOpen]);

   return (
      <React.Fragment>
         {isOpen && (
            <div
               className={`
				   modal
				   modal--${isExpanded ? "expanded" : ""}
				    ${className}`}
               data-testid="modal"
            >
               <div className="modal__overlay" onClick={handleToggler}></div>
               <div className="modal__wrapper">
                  {withCloseButton && (
                     <CloseIcon className="modal__close-icon" onClick={handleToggler} />
                  )}
                  {children}
               </div>
            </div>
         )}
      </React.Fragment>
   );
};

export default React.memo(Modal);
