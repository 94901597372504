// React
import { Suspense, lazy, useEffect } from "react";
// Styles
import "@styles/_base.scss";
import "@styles/_global.scss";
// Router
import { Routes, Route, unstable_HistoryRouter as Router } from "react-router-dom";
// Layouts
import LoadingOverlay from "@layouts/LoadingOverlay";
import IdleTimer from "@layouts/IdleTimer";
import PopStateQueryRemover from "@layouts/PopStateQueryRemover";
// Routes
import AdminRoute from "@routes/AdminRoute";
import ProtectedRoute from "@routes/ProtectedRoute";
import PermissionRoute from "@routes/PermissionRoute";
// Components
import Toast from "@components/Toast";
// Utils
import { history } from "@utils/history";
// Hooks
import { LastLocationProvider } from "@hooks/useLastLocation";
import { useSelector } from "react-redux";
// Types
import { RootState } from "@redux/rootReducer";
import { GoogleTagEvent, pushCustomGTMEvent } from "@utils/googleTagManager";
// Lazy loaded pages
const Home = lazy(() => import("./pages/Home"));
const SignIn = lazy(() => import("./pages/SignIn"));
const ListProduct = lazy(() => import("./pages/ListProduct"));
const Search = lazy(() => import("./pages/Search"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const ThankYouEmailVerified = lazy(() => import("./pages/ThankYouEmailVerified"));
const ProductInList = lazy(() => import("./pages/ProductInList"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const Product = lazy(() => import("./pages/Product"));
const AccountManagement = lazy(() => import("./pages/AccountManagement"));
const Catalog = lazy(() => import("./pages/Catalog"));
const CatalogBranch = lazy(() => import("./pages/CatalogBranch"));
const Matches = lazy(() => import("./pages/Matches"));
const MatchesBranch = lazy(() => import("./pages/MatchesBranch/indexOld"));
const EmailBuyer = lazy(() => import("./pages/EmailBuyer"));
// const Transactions = lazy(() => import("./pages/Transactions"));

const App = () => {
   const { user } = useSelector((state: RootState) => state.auth);

   useEffect(() => {
      if (user.email) {
         pushCustomGTMEvent({
            event: GoogleTagEvent.ClarityInit,
         });

         pushCustomGTMEvent({
            event: GoogleTagEvent.ClarityIdentify,
            email: user.email,
            friendlyName: `${user.firstName?.charAt(0)}. ${user.lastName}`,
         });
      }
   }, [user.username]);

   return (
      <Suspense fallback={<LoadingOverlay />}>
         <div className="App">
            <Router history={history}>
               <Toast />
               <IdleTimer />
               <PopStateQueryRemover unwantedQueries={["modal"]}>
                  <LastLocationProvider>
                     <Routes>
                        {/* Public Route */}
                        <Route element={<SignIn />} path="/sign-in" />
                        <Route element={<ChangePassword />} path="/change-password" />
                        <Route element={<PageNotFound />} path="*" />
                        <Route
                           element={<ThankYouEmailVerified />}
                           path="/thank-you-email-verified"
                        />
                        {/* Private Route */}
                        <Route element={<ProtectedRoute />}>
                           <Route element={<Home />} path="/" />
                           <Route element={<Search />} path="/search/:search" />
                           <Route element={<Product />} path="/product/:id" />
                           <Route element={<ListProduct />} path="/list-product" />
                           <Route element={<ProductInList />} path="/product-in-list" />
                           <Route element={<Catalog />} path="/catalog" />
                           <Route
                              element={<CatalogBranch />}
                              path="/catalog-branch/:branch"
                           />
                           {/* <Route element={<Transactions />} path="/transactions" /> */}
                           <Route element={<Matches />} path="/matches" />
                           <Route element={<PermissionRoute />}>
                              <Route
                                 element={<MatchesBranch />}
                                 path="/matches-branch/:branch"
                              />
                           </Route>
                           <Route element={<EmailBuyer />} path="/email-buyer" />
                           {/* Admin Route */}
                           <Route element={<AdminRoute />}>
                              <Route
                                 element={<AccountManagement />}
                                 path="/account-management"
                              />
                           </Route>
                        </Route>
                     </Routes>
                  </LastLocationProvider>
               </PopStateQueryRemover>
            </Router>
         </div>
      </Suspense>
   );
};

export default App;
