import { createSlice } from "@reduxjs/toolkit";
// Types
import { IStates } from "./types";

const productList = createSlice({
   name: "productList",
   initialState: {
      products: [],
      error: {
         type: "",
         message: "",
         data: {},
      },
      code: "",
      total: 0,
      perPage: 0,
      page: 0,
   } as IStates,
   reducers: {
      productListRequest: (state, { payload }) => {
         state.code = payload.code;
         state.error = {
            data: {},
         };
      },
      productListResolved: (state, { payload }) => {
         state.code = payload.code;
         state.error = payload.error ?? {
            data: {},
         };
         state.products = payload.products ?? state.products;
         state.total = payload.total ?? state.total;
         state.perPage = payload.perPage ?? state.perPage;
         state.page = payload.page ?? state.page;
      },
      productListRejected: (state, { payload }) => {
         state.total = payload.totalResult ?? state.total;
         state.code = payload.code;
         state.error = payload.error;
      },
   },
});

export const { productListRequest, productListResolved, productListRejected } =
   productList.actions;
export default productList.reducer;
