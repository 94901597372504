import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IStates } from "./types";

const auth = createSlice({
   name: "auth",
   initialState: {
      user: {},
      code: "",
      data: {},
      userLastSignedIn: null,
      status: status.IDLE,
   } as IStates,
   reducers: {
      authClearCode: (state) => {
         state.code = "";
         state.status = status.RESOLVED;
      },
      authSignOut: (state, { payload }) => {
         state.user = {};
         state.data = {};
         state.status = status.RESOLVED;
         state.userLastSignedIn = null;
         state.code = payload.code as string;
      },
      authUpdateCode: (state, { payload }) => {
         state.code = payload;
      },
      authRequest: (state, { payload }) => {
         if ("user" in payload) {
            state.user = {};
         }
         state.code = payload.code;
         state.status = status.PENDING;
      },
      authResolved: (state, { payload }) => {
         if ("user" in payload) {
            state.user = payload.user;
         }

         if ("data" in payload) {
            state.data = payload.data;
         }

         if ("userLastSignedIn" in payload) {
            state.userLastSignedIn = payload.userLastSignedIn;
         }

         state.code = payload.code as string;
         state.status = status.RESOLVED;
      },
      authRejected: (state, { payload }) => {
         if ("user" in payload) {
            state.user = payload.user;
         }

         if ("userLastSignedIn" in payload) {
            state.userLastSignedIn = payload.userLastSignedIn;
         }

         state.code = payload.code as string;
         state.data = payload.data;
         state.status = status.REJECTED;
      },
   },
});

export const {
   authRequest,
   authResolved,
   authRejected,
   authClearCode,
   authSignOut,
   authUpdateCode,
} = auth.actions;
export default auth.reducer;
