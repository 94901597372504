import { put, takeEvery, call, takeLatest, takeLeading } from "redux-saga/effects";
// Actions
import { authRejected, authRequest, authResolved, authSignOut } from "../../slice";
// Handlers
import {
   handleChangePassword,
   handleGetSession,
   handleSignIn,
   handleSignOut,
   handleUpdateUser,
   handleValidateMfaTotpCode,
   handleValidateSignInTotpCode,
} from "../handlers";
// Utils
import {
   authStatusCode as statusCode,
   authRequestType as reqType,
} from "../../../utils/constants";
// Types
import {
   TChangePasswordPayload,
   TGetSessionPayload,
   TSignInPayload,
   TValidateSignInTotpPayload,
   TValidateTotpPayload,
} from "./types";
import { AnyAction } from "@reduxjs/toolkit";

function* signIn({ Username, Password }: AnyAction | TSignInPayload): unknown {
   try {
      const result: Awaited<PromiseLike<ReturnType<typeof handleSignIn>>> = yield call(
         handleSignIn,
         {
            Username,
            Password,
         }
      );
      yield put(authResolved(result));
   } catch (err) {
      yield put(authRejected(err));
   }
}

function* changePassword({
   cogniUser,
   newPassword,
   username,
}: AnyAction | TChangePasswordPayload): unknown {
   try {
      const result: Awaited<PromiseLike<ReturnType<typeof handleChangePassword>>> =
         yield call(handleChangePassword, {
            cogniUser,
            newPassword,
            username,
         });
      yield put(authResolved(result));
   } catch (err) {
      yield put(authRejected(err));
   }
}

function* validateMfaTotpCode({
   cogniUser,
   totpCode,
}: AnyAction | TValidateTotpPayload): unknown {
   try {
      const result: Awaited<PromiseLike<ReturnType<typeof handleValidateMfaTotpCode>>> =
         yield call(handleValidateMfaTotpCode, { cogniUser, totpCode });
      yield put(authResolved(result));
   } catch (err) {
      yield put(authRejected(err));
   }
}

function* validateSignInTotpCode({
   cogniUser,
   totpCode,
}: AnyAction | TValidateSignInTotpPayload): unknown {
   try {
      const result: Awaited<
         PromiseLike<ReturnType<typeof handleValidateSignInTotpCode>>
      > = yield call(handleValidateSignInTotpCode, { cogniUser, totpCode });
      yield put(authResolved(result));
   } catch (err) {
      yield put(authRejected(err));
   }
}

function* signOut(): unknown {
   try {
      const result: Awaited<PromiseLike<ReturnType<typeof handleSignOut>>> = yield call(
         handleSignOut
      );
      if (result) {
         yield put(
            authSignOut({
               code: statusCode.SIGN_OUT_SUCCESS,
            })
         );
      } else {
         yield put(
            authRejected({
               code: statusCode.SIGN_OUT_FAILURE,
            })
         );
      }
   } catch (err) {
      yield put(
         authRejected({
            code: statusCode,
            data: {
               error: err,
            },
         })
      );
   }
}

function* getSession({ shouldUpdateToken }: AnyAction | TGetSessionPayload): unknown {
   try {
      const result: Awaited<Promise<PromiseLike<ReturnType<typeof handleGetSession>>>> =
         yield call(handleGetSession, {
            shouldUpdateToken,
         });
      yield put(authResolved(result));
      if (result.shouldUpdateUser) {
         yield put(
            authRequest({
               type: reqType.UPDATE_USER,
               code: result.code,
            })
         );
      }
   } catch (err) {
      yield put(authRejected(err));
   }
}

function* updateUser(): unknown {
   try {
      const result: Awaited<PromiseLike<ReturnType<typeof handleUpdateUser>>> =
         yield call(handleUpdateUser);
      yield put(authResolved(result));
   } catch (err) {
      yield put(authRejected(err));
   }
}

export default function* (): unknown {
   // Request's
   yield takeEvery("auth/authRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(reqType.SIGN_IN, signIn);
   yield takeLeading(reqType.UPDATE_USER, updateUser);
   yield takeLeading(reqType.SIGN_OUT, signOut);
   yield takeLatest(reqType.VALIDATE_MFA_TOTP_CODE, validateMfaTotpCode);
   yield takeLatest(reqType.VALIDATE_SIGN_IN_TOTP_CODE, validateSignInTotpCode);
   yield takeLatest(reqType.CHANGE_PASSWORD, changePassword);
   yield takeLeading(reqType.GET_SESSION, getSession);
}
