/**
 * We created this utility to submit form when "enter" is hit.
 * Why? because form doesn't work on inputs that is individually wrapped
 * with div.
 *
 * How to use -> just spread it into your form and invoke it then pass your desired callback.
 *
 * Example: <form {...formSubmitOnEnter(myCb())}></form>
 */

import React from "react";

export const formSubmitOnEnter = (cb: () => unknown) => {
   return {
      onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
         if (event?.keyCode === 13 || event?.key === "Enter") {
            event.preventDefault();
            cb();
         }
      },
   };
};
