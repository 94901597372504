import {
   CallEffect,
   ForkEffect,
   PutEffect,
   SagaReturnType,
   call,
   put,
   takeEvery,
   takeLatest,
} from "redux-saga/effects";
// Utils
import {
   transactionsRequestType as reqType,
   stateType,
} from "@features/Transactions/utils/constants";
// Types
import { TPayload } from "./types";
import { AnyAction } from "@reduxjs/toolkit";
// Actions
import {
   transactionsRejected,
   transactionsRequest,
   transactionsResolved,
} from "../../slice";
// Handlers
import {
   handleDownloadTransactions,
   handleGetBranches,
   handleGetRanges,
   handleGetTransactions,
} from "../handlers";

function* getTransactions({
   filters,
   order,
   currency,
   pagination,
}: AnyAction & TPayload): Generator<CallEffect | PutEffect, void, never> {
   try {
      // Request for the ranges first
      yield put(
         transactionsRequest({
            type: reqType.GET_TRANSACTIONS_RANGES,
            currency,
            stateType: stateType.ranges,
         })
      );
      // Then request for the transactions
      const res: SagaReturnType<typeof handleGetTransactions> = yield call(
         handleGetTransactions,
         {
            filters,
            order,
            currency,
            pagination,
         }
      );
      yield put(transactionsResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetTransactions>;
      yield put(transactionsRejected(error));
   }
}

function* downloadTransactions({
   filters,
   order,
   currency,
}: AnyAction & Omit<TPayload, "pagination">): Generator<
   CallEffect | PutEffect,
   void,
   never
> {
   try {
      // Request for the ranges first
      yield put(
         transactionsRequest({
            type: reqType.GET_TRANSACTIONS_RANGES,
            currency,
            stateType: stateType.ranges,
         })
      );
      // Then request for the transactions
      const res: SagaReturnType<typeof handleDownloadTransactions> = yield call(
         handleDownloadTransactions,
         {
            filters,
            order,
            currency,
         }
      );
      yield put(transactionsResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetTransactions>;
      yield put(transactionsRejected(error));
   }
}

function* getBranches({}: AnyAction & TPayload): Generator<
   CallEffect | PutEffect,
   void,
   never
> {
   try {
      const res: SagaReturnType<typeof handleGetBranches> = yield call(handleGetBranches);
      yield put(transactionsResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetBranches>;
      yield put(transactionsRejected(error));
   }
}

function* getRanges({
   currency,
}: AnyAction & TPayload): Generator<CallEffect | PutEffect, void, never> {
   try {
      const res: SagaReturnType<typeof handleGetRanges> = yield call(handleGetRanges, {
         currency,
      });
      yield put(transactionsResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetRanges>;
      yield put(transactionsRejected(error));
   }
}

export default function* (): Generator<PutEffect | ForkEffect, void, never> {
   // Request's
   yield takeEvery(
      "transactions/transactionsRequest",
      function* ({ payload }: AnyAction) {
         yield put({
            type: payload.type,
            ...payload,
         });
      }
   );
   // Actions
   yield takeLatest(reqType.GET_TRANSACTIONS_RANGES, getRanges);
   yield takeLatest(reqType.GET_TRANSACTIONS_BRANCHES, getBranches);
   yield takeLatest(reqType.GET_TRANSACTIONS, getTransactions);
   yield takeLatest(reqType.DOWNLOAD_TRANSACTIONS, downloadTransactions);
}
