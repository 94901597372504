import React, { createContext, ReactNode } from "react";
// Types
import { TActiveMenu, TLink } from ".";

interface IProps extends TContextValues {
   children: ReactNode;
   [x: string]: unknown;
}

type TContextValues = {
   handleSignOut?: () => void;
   activeMenu?: TActiveMenu | null;
   handleToggleMenu?: (type: TActiveMenu) => void;
   isSaveProductModalToggled?: boolean;
   handleSaveProductModalToggler?: (newValue?: boolean) => void;
   handleNavigateToHomePage?: () => void;
   links?: TLink[];
   isMobile?: boolean;
};

export const Context = createContext<TContextValues>({});

const ContextProvider = ({ children, ...rest }: IProps) => {
   return <Context.Provider value={{ ...rest }}>{children}</Context.Provider>;
};

// As we do not know the props that will be passed into our provider we will make the
export default React.memo(ContextProvider, (p, n) => {
   if (p.activeMenu !== n.activeMenu) {
      return false;
   }

   if (p.isSaveProductModalToggled !== n.isSaveProductModalToggled) {
      return false;
   }

   if (p.isMobile !== n.isMobile) {
      return false;
   }

   return true;
});
