import { SagaReturnType, call, put, takeEvery, takeLatest } from "redux-saga/effects";
// Utils
import { bannerRequestType } from "@features/Banner/utils/constants";
// Handlers
import { handleGetBanner } from "../handler";
// Actions
import { bannerRejected, bannerResolved } from "../../slice";
// Types
import { AnyAction } from "@reduxjs/toolkit";

function* getBanner({}) {
   try {
      const res: SagaReturnType<typeof handleGetBanner> = yield call(handleGetBanner);
      yield put(bannerResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetBanner>;
      yield put(bannerRejected(error));
   }
}

export default function* () {
   // Request's
   yield takeEvery("banner/bannerRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(bannerRequestType.GET_BANNER, getBanner);
}
