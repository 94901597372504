import React from "react";
// Router
import { Outlet, Navigate } from "react-router-dom";
// Redux
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";

/**
 * This component should be inside the protected route
 * because we are depending the admin route on auth state
 * from redux and protected route is the one who is requesting it
 * on page load.
 */

const AdminRoute: React.FC = () => {
   const { user } = useSelector((state: RootState) => state.auth);

   return user?.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default React.memo(AdminRoute);
