import { SagaReturnType, put, takeEvery, takeLatest, call } from "redux-saga/effects";
// Types
import { AnyAction } from "@reduxjs/toolkit";
import { TEmailBuyerPayload } from "./types";
// Utils
import { emailBuyerRequestType as reqType } from "@features/EmailBuyer/utils/constants";
// Actions
import { emailBuyerResolved } from "../../slice";
// Handlers
import { handleSendRequests } from "../handlers";

function* sendRequests({ requests }: TEmailBuyerPayload & AnyAction) {
   const res: SagaReturnType<typeof handleSendRequests> = yield call(handleSendRequests, {
      requests,
   });
   yield put(emailBuyerResolved(res));
}

export default function* () {
   // Request's
   yield takeEvery("emailBuyer/emailBuyerRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(reqType.SEND_REQUEST, sendRequests);
}
