import React from "react";
// Hooks
import { useToast } from "@hooks/useToast";
// Styles
import "./styles/main.scss";
// Components
import ToastItem from "./subComponents/ToastItem";
// Types
import { TToast } from "./context";

const Toast = () => {
   const { listOfToast } = useToast();
   return (
      <div className="toast">
         {listOfToast?.map((toast: TToast) => (
            <ToastItem key={toast.id} toast={toast} />
         ))}
      </div>
   );
};

export default React.memo(Toast);
