import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
// Handlers
import {
   handleGetProduct,
   handleGetCategories,
   handleGetStock,
   handleGetMatch,
   handleGetMatchOld,
} from "../handler";
// Types
import { TProductPayload } from "./types";
import { AnyAction } from "@reduxjs/toolkit";
import { SagaReturnType } from "redux-saga/effects";
// Actions
import { productResolved, productRejected, productRequest } from "../../slice";
// Utils
import {
   productRequestType,
   productRequestType as reqType,
   stateType,
} from "@features/Product/utils/constants";

function* getFullProduct({ pid, currency }: AnyAction & TProductPayload): unknown {
   // We will call all the request's below
   yield all(
      Object.values(productRequestType).map((value, index) => {
         /**
          * We will skip the index 0 as it is the GET_FULL_PRODUCT
          * if requested will loop the getFullProduct process
          */
         // Skip new match
         if (Object.values(stateType)[index] === stateType.match) return;

         if (index) {
            return put(
               productRequest({
                  type: value,
                  stateType: Object.values(stateType)[index],
                  pid: pid as string,
                  currency,
               })
            );
         }
      })
   );
}

function* getProduct({ pid }: AnyAction & TProductPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetProduct> = yield call(handleGetProduct, {
         pid,
      });
      yield put(productResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetProduct>;
      yield put(productRejected(error));
   }
}

function* getCategories({ pid }: AnyAction & TProductPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetCategories> = yield call(
         handleGetCategories,
         {
            pid,
         }
      );
      yield put(productResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetCategories>;
      yield put(productRejected(error));
   }
}

function* getStocks({ pid }: AnyAction & TProductPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetStock> = yield call(handleGetStock, {
         pid,
      });
      yield put(productResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetStock>;
      yield put(productRejected(error));
   }
}

function* getMatch({ pid, currency }: AnyAction & TProductPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetMatch> = yield call(handleGetMatch, {
         pid,
         currency,
      });
      yield put(productResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetMatch>;
      yield put(productRejected(error));
   }
}

function* getMatchOld({ pid, currency }: AnyAction & TProductPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetMatchOld> = yield call(
         handleGetMatchOld,
         {
            pid,
            currency,
         }
      );
      yield put(productResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetMatchOld>;
      yield put(productRejected(error));
   }
}

export default function* (): unknown {
   // Request's
   yield takeEvery("product/productRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(reqType.GET_FULL_PRODUCT, getFullProduct);
   yield takeLatest(reqType.GET_PRODUCT, getProduct);
   yield takeLatest(reqType.GET_PRODUCT_CATEGORIES, getCategories);
   yield takeLatest(reqType.GET_PRODUCT_STOCK, getStocks);
   yield takeLatest(reqType.GET_PRODUCT_MATCH, getMatch);
   yield takeLatest(reqType.GET_PRODUCT_MATCH_OLD, getMatchOld);
}
