import { axios } from "@redux/store";
import { default as axiosOrig } from "axios";
// Utils
import { stateType } from "@features/Transactions/utils/constants";
// Types
import { CallEffect, call } from "redux-saga/effects";
import {
   TError,
   TTransactionBranch,
   TTransactionItem,
   TTransactionNumberRange,
} from "../../slice/types";
import { TStateType } from "@features/Transactions/utils/constants";
import { TPayload } from "../request/types";
import {
   TDownloadTransactionsResponse,
   TGetBranchResponse,
   TGetTransactionNumberRangeResponse,
   TGetTransactionsResponse,
} from "./types";
import { AxiosError } from "axios";
import { TDownloadUrl } from "@features/Matches/store/slice/types";

export function* handleGetTransactions({
   order,
   currency,
   filters,
   pagination,
}: TPayload): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TTransactionItem;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      // Fetch the transactions
      const res: TGetTransactionsResponse = yield call(async () => {
         return axios({
            url: "/transactions",
            method: "POST",
            payload: {
               filters,
               order,
               pagination,
               currency: currency ?? "USD",
            },
            cancelToken: cancelToken.token,
         });
      });

      return {
         items: {
            transactions: res.data.items,
            total: res.data.total,
            page: res.data.page,
            perPage: res.data.perPage,
         },
         stateType: stateType.transactions,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         items: {
            transactions: [],
            total: 0,
            page: 0,
            perPage: 0,
         },
         stateType: stateType.transactions,
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleDownloadTransactions({
   order,
   currency,
   filters,
}: Omit<TPayload, "pagination">): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TDownloadUrl;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      // Fetch the transactions
      const res: TDownloadTransactionsResponse = yield call(async () => {
         return axios({
            url: "/transactions/download",
            method: "POST",
            payload: {
               filters,
               order,
               currency: currency ?? "USD",
            },
            cancelToken: cancelToken.token,
         });
      });

      return {
         items: {
            url: res.data.url,
         },
         stateType: stateType.download,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         items: {
            transactions: [],
            total: 0,
            page: 0,
            perPage: 0,
         },
         stateType: stateType.transactions,
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetRanges({ currency }: TPayload): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TTransactionNumberRange;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      // Then fetch the ranges
      const ranges: TGetTransactionNumberRangeResponse = yield call(() => {
         return axios({
            url: "/transactions/filters",
            method: "POST",
            payload: {
               currency,
            },
            cancelToken: cancelToken.token,
         });
      });

      return {
         items: ranges.data,
         stateType: stateType.ranges,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.ranges,
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetBranches(): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TTransactionBranch[];
      error?: TError;
   },
   never
> {
   try {
      const branches: TGetBranchResponse = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      // Then update the branches shape
      const branchesUpdatedShape = branches.data.items.map((branch) => {
         return {
            name: branch.name,
            id: branch.id,
         };
      });

      return {
         items: branchesUpdatedShape,
         stateType: stateType.branches,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.branches,
         error: error.response?.data.error,
      };
   }
}
