// Types
import { CallEffect, call } from "redux-saga/effects";
// Types
import { TEmailBuyerPayload } from "../request/types";
// Axios
import { axios, store } from "@redux/store";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "@redux/rootReducer";
import { status } from "@utils/constants";
import { TEmailItem, TError } from "../../slice/types";

export function* handleSendRequests({ requests }: TEmailBuyerPayload): Generator<
   CallEffect,
   {
      requests: TEmailItem[];
   },
   never
> {
   const response: Promise<AxiosResponse>[] = [];
   // We gonna iterate and the request and push the axios response to the array above
   for (let i = 0; i < requests.length; i++) {
      response.push(
         axios({
            url: "/suppliers/chat",
            method: "POST",
            payload: requests[i],
         })
      );
   }
   // Then we will all settled them using Promise setteled so that even if it does failed it will still return the result
   const res: PromiseSettledResult<AxiosResponse>[] = yield call(async () => {
      return await Promise.allSettled(response);
   });
   // Then we will get the state
   const state = JSON.parse(
      JSON.stringify(store.getState().emailBuyer)
   ) as RootState["emailBuyer"];
   // Then we will update the shape so that it is the same with the TEmailItem array
   const updatedResult = res.map((result, index) => {
      if (result.status === "fulfilled") {
         return {
            ...state?.requests[index],
            status: status.RESOLVED,
            error: {},
         };
      } else {
         const error = result.reason as AxiosError<{
            error: TError;
         }>;
         return {
            ...state?.requests[index],
            status: status.REJECTED,
            error: error.response?.data.error,
         };
      }
   });

   return {
      requests: updatedResult as TEmailItem[],
   };
}
