import React, { ReactNode } from "react";
// Styles
import "./styles/styles.scss";

interface IProps {
   children: ReactNode;
   className?: string;
}

/**
 * This sub component accepts className and children components
 * so you can wrap them inside this component.
 */
const Drawer = ({ children, className }: IProps) => {
   return <div className={`nav-bar__drawer ${className as string}`}>{children}</div>;
};

export default React.memo(Drawer);
