import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IStates, TPayloadType } from "./types";
import { stateType } from "@features/AccountManagement/utils/constants";

const accountManagement = createSlice({
   name: "accountManagement",
   initialState: {
      users: {
         data: {
            items: [],
            page: 0,
            perPage: 0,
            total: 0,
         },
         status: status.IDLE,
         error: {},
      },
      user: {
         data: {
            sub: "",
            email: "",
            branchId: "",
            role: "user",
            permissions: [],
            firstName: "",
            lastName: "",
         },
         status: status.IDLE,
         error: {},
      },
      editUser: {
         data: "",
         status: status.IDLE,
         error: {},
      },
      createUser: {
         data: "",
         status: status.IDLE,
         error: {},
      },
      downloadUserLogs: {
         data: "",
         status: status.IDLE,
         error: {},
      },
      removeUser: {
         data: "",
         status: status.IDLE,
         error: {},
      },
      resetPassword: {
         data: "",
         status: status.IDLE,
         error: {},
      },
      branch: {
         data: [],
         status: status.IDLE,
         error: {},
      },
   } as IStates,
   reducers: {
      accountManagementRequest: (state, { payload }: TPayloadType) => {
         // Put back the status of the rest state to idle
         Object.values(stateType).forEach((type) => {
            /**
             * If state status is not pending then we will set it to idle
             * so that we will not reset a pending request.
             */
            if (state[type].status !== status.PENDING) {
               state[type].status = status.IDLE;
            }
         });
         // Then we will update the current state type to pending
         state[payload.stateType].status = status.PENDING;
         state[payload.stateType].error = {
            data: {},
         };
      },
      accountManagementResolved: (state, { payload }: TPayloadType) => {
         state[payload.stateType] = {
            data: payload.data,
            status: status.RESOLVED,
            error: {
               data: {},
            },
         };
      },
      accountManagementRejected: (state, { payload }: TPayloadType) => {
         state[payload.stateType] = {
            data: payload.data,
            status: status.REJECTED,
            error: payload.error,
         };
      },
   },
});

export const {
   accountManagementRequest,
   accountManagementResolved,
   accountManagementRejected,
} = accountManagement.actions;

export default accountManagement.reducer;
