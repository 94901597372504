// React
import ReactDOM from "react-dom/client";
// Components
import App from "./App";
// Redux
import { persistor, store } from "@redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// Context
import ToastProvider from "@components/Toast/context";
// Sentry Init
import * as Sentry from "@sentry/react";

Sentry.init({
   dsn: import.meta.env.VITE_SENTRY_DSN as string,
   integrations: [
      new Sentry.BrowserTracing({
         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
         tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
   ],
   // Disable Sentry in local development
   enabled: import.meta.env.VITE_STAGE === "prod" || import.meta.env.VITE_STAGE === "dev",
   // Performance Monitoring
   tracesSampleRate: 1.0, // Capture 100% of the transactions
   // Session Replay
   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLDivElement);

root.render(
   <Provider store={store}>
      <PersistGate persistor={persistor}>
         <ToastProvider>
            <App />
         </ToastProvider>
      </PersistGate>
   </Provider>
);
