import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
import { stateType } from "@features/Search/utils/constants";
// Types
import { IStates, TPayload } from "./types";

const searchResult = createSlice({
   name: "searchResult",
   initialState: {
      currency: {
         items: {
            currency: "USD",
            locale: "en-US",
            symbol: "$",
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
      search: {
         items: {
            products: [],
            total: 0,
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
      branch: {
         items: [],
         error: {
            data: {},
         },
         status: status.IDLE,
      },
      suppliers: {
         items: [],
         error: {
            data: {},
         },
         status: status.IDLE,
      },
      warehouses: {
         items: [],
         error: {
            data: {},
         },
         status: status.IDLE,
      },
      price: {
         items: {
            min: 0,
            max: 100,
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
   } as IStates,
   reducers: {
      searchResultRequest: (state, { payload }: TPayload) => {
         // Put back the status of the rest state to idle
         Object.values(stateType).forEach((key) => {
            /**
             * If state status is not pending then we will set it to idle
             * so that we will not reset a pending request.
             */
            if (key !== payload.stateType && state[key].status !== status.PENDING) {
               state[key].status = status.IDLE;
            } else {
               // Then update the status and error for the passed stateType
               state[key].status = status.PENDING;
               state[key].error = {
                  data: {},
               };
            }
         });
      },
      searchResultResolved: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items;
         state[payload.stateType].error = {
            data: {},
         };
         state[payload.stateType].status = status.RESOLVED;
      },
      searchResultRejected: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items ?? state[payload.stateType].items;
         state[payload.stateType].error = payload.error;
         state[payload.stateType].status = status.REJECTED;
      },
   },
});

export const { searchResultRequest, searchResultResolved, searchResultRejected } =
   searchResult.actions;
export default searchResult.reducer;
