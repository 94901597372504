import React from "react";
// Styles
import "./styles/styles.scss";

interface IProps {
   icon: React.ElementType;
   number?: string | number | null;
   isActive?: boolean;
   className?: string;
   [x: string]: unknown;
}

/**
 * This sub-component accepts
 * - icon => a jsx
 * - number => Integer
 */
const NavigationIcon = ({
   icon: Icon,
   className = "",
   number = 0,
   isActive = false,
   ...rest
}: IProps) => {
   return (
      <div
         className={`
			${className}
			nav-bar__icon 
			nav-bar__icon--${isActive ? "active" : ""}`}
         {...rest}
      >
         <Icon className="nav-bar__icon__icon" />
         {number !== null && <p className="nav-bar__icon__floating-text">{number}</p>}
      </div>
   );
};

export default React.memo(NavigationIcon);
