export const productRequestType = {
   GET_FULL_PRODUCT: "GET_FULL_PRODUCT",
   GET_PRODUCT: "GET_PRODUCT",
   GET_PRODUCT_MATCH: "GET_PRODUCT_MATCH",
   GET_PRODUCT_MATCH_OLD: "GET_PRODUCT_MATCH_OLD",
   GET_PRODUCT_CATEGORIES: "GET_PRODUCT_CATEGORIES",
   GET_PRODUCT_STOCK: "GET_PRODUCT_STOCK",
} as const;

export type TReqType = (typeof productRequestType)[keyof typeof productRequestType];

export const productCacheKey = {
   GET_PRODUCT: "GET_PRODUCT",
   GET_MATCH: "GET_MATCH",
   GET_STOCK: "GET_STOCK",
};

export const stateType = {
   product: "product",
   categories: "categories",
   match: "match",
   matchOld: "matchOld",
   stock: "stock",
} as const;

export type TStateType = (typeof stateType)[keyof typeof stateType];
