import { useState, useCallback } from "react";

type ConfigType = {
   defaultValue?: boolean;
};

type ReturnedValue = [boolean, (newValue?: boolean) => void];

export const useToggle = (configs?: ConfigType): ReturnedValue => {
   const [isToggled, setToggle] = useState(configs?.defaultValue || false);

   const handleToggler = useCallback(
      (newValue?: boolean) => {
         if (typeof newValue === "boolean") {
            setToggle(newValue);
         } else {
            setToggle((prev) => !prev);
         }
      },
      [isToggled]
   );

   return [isToggled, handleToggler];
};
