import { useCallback, useMemo, useRef, useState } from "react";
// Styles
import "./styles/main.scss";
// Hooks
import { useToggle } from "@hooks/useToggle";
import { useMediaQuery } from "@hooks/useMediaQuery";
// Redux
import { authRequest } from "@features/Auth/store/slice";
import { useDispatch, useSelector } from "react-redux";
// Views
import Desktop from "./views/Desktop";
import Mobile from "./views/Mobile";
// Context
import ContextProvider from "./context";
// Screens
import screen from "@styles/screens/_sizes.module.scss";
// Utils
import { authRequestType } from "@features/Auth/utils/constants";
// Router
import { useNavigate } from "react-router-dom";
// Features
import SaveProductListModal from "@features/ProductList/components/SaveListModal";
// Types
import { RootState } from "@redux/rootReducer";
import Banner from "@features/Banner/components/Banner";

export type TActiveMenu = "USER" | "PRODUCT_LIST" | "CURRENCY" | null;
export type TLink = {
   value: string;
   path: string;
};

const NavigationBar = () => {
   const [activeMenu, setActiveMenu] = useState<TActiveMenu | null>(null);
   const [isSaveProductModalToggled, handleSaveProductModalToggler] = useToggle();
   // Refs
   const navRef = useRef<HTMLDivElement | null>(null);
   // Media queries
   const isMobile = useMediaQuery(`(max-width: ${screen?.mobile})`);
   // Redux methods
   const { user } = useSelector((state: RootState) => state.auth);
   const dispatch = useDispatch();
   // Router methods
   const navigate = useNavigate();

   const links = useMemo<TLink[]>(() => {
      const linksArr = [
         {
            value: "Catalog",
            path: "/catalog",
         },
         {
            value: "Matches",
            path: "/matches",
         },
         // {
         //    value: "Transactions",
         //    path: "/transactions",
         // },
      ];

      return linksArr;
   }, [JSON.stringify(user)]);

   const handleToggleMenu = (type: TActiveMenu): void => {
      setActiveMenu(type === activeMenu ? null : type);
   };

   const handleSignOut = useCallback(() => {
      dispatch(
         authRequest({
            type: authRequestType.SIGN_OUT,
         })
      );
   }, []);

   const handleNavigateToHomePage = useCallback(() => {
      navigate("/");
   }, []);

   const handleBannerOnChange = () => {
      // We wrap it inside a timeout so that this will get invoked after the ref updated so that we can get the latest value of the element
      setTimeout(() => {
         // On banner mount and update we will also update the navbar height variable from css
         document.documentElement.style?.setProperty(
            "--nav-bar-height",
            `${navRef.current?.offsetHeight}px`
         );
      });
   };

   return (
      <ContextProvider
         handleSignOut={handleSignOut}
         activeMenu={activeMenu}
         handleToggleMenu={handleToggleMenu}
         isSaveProductModalToggled={isSaveProductModalToggled}
         handleSaveProductModalToggler={handleSaveProductModalToggler}
         handleNavigateToHomePage={handleNavigateToHomePage}
         links={links}
         isMobile={isMobile}
      >
         <div className="nav" ref={navRef}>
            <Banner onChange={handleBannerOnChange} />
            {isMobile ? <Mobile /> : <Desktop />}
         </div>

         {isSaveProductModalToggled && (
            <SaveProductListModal
               isOpen={isSaveProductModalToggled}
               handleToggler={handleSaveProductModalToggler}
            />
         )}
      </ContextProvider>
   );
};

export default NavigationBar;
