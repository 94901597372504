export const capitalizedFirstLetter = (text: string): string => {
   const splittedLabel: string[] = text.split(" ");
   const reduced = splittedLabel.reduce((a, c) => {
      const firstLetter = c.substring(1, 0);
      const wordWithoutFirstLetter = c.substring(1);

      a += " " + `${firstLetter.toUpperCase()}${wordWithoutFirstLetter.toLowerCase()}`;
      return a;
   }, "");

   return reduced;
};
