import React, { useContext, useLayoutEffect } from "react";
// Components
import Drawer from "../../subComponents/Drawer";
import UserMenu from "../../subComponents/UserMenu";
import ProductList from "../../subComponents/ProductList";
import SearchInputForm from "../../subComponents/SearchInputForm";
import NavigationIcon from "../../subComponents/NavigationIcon";
import NavigationLinks from "../../subComponents/NavigationLinks";
import CurrencyList from "../../subComponents/CurrencyList";
// Icons
import LogoIcon from "@icons/LogoIcon.svg";
import ListCheckListIcon from "@icons/ListCheckListIcon.svg";
import CurrencyIcon from "@icons/Currency.svg";
// Context
import { Context } from "../../context";
// Keymaster
import key from "keymaster";
// Styles
import "./styles/styles.scss";
import { useIsClickedOutsideComponent } from "@hooks/useIsClickedOutsideComponent";
// Router
import { useLocation } from "react-router-dom";

const Desktop = () => {
   // Others
   const { targetRef, isClickedOutside } = useIsClickedOutsideComponent<HTMLDivElement>();
   // Context
   const { activeMenu, handleToggleMenu, handleNavigateToHomePage, links } =
      useContext(Context);
   // Router methods
   const location = useLocation();

   const handleClickLogo = () => {
      if (!key.ctrl && !key["⌘"]) {
         handleNavigateToHomePage?.();
      } else {
         window.open("/");
      }
   };

   useLayoutEffect(() => {
      if (isClickedOutside) {
         handleToggleMenu?.(null);
      }
   }, [isClickedOutside]);

   useLayoutEffect(() => {
      handleToggleMenu?.(null);
   }, [location]);

   return (
      <div
         className={`
			nav-bar
			pt-3 pb-3`}
      >
         <div className="nav-bar__container">
            <div className="nav-bar__wrapper">
               <LogoIcon className="nav-bar__logo" onClick={handleClickLogo} />
               <div className="nav-bar__search-input-wrapper">
                  <SearchInputForm />
               </div>
               <div className="nav-bar__links-wrapper" ref={targetRef}>
                  <NavigationLinks links={links} />
                  <Drawer className="nav-bar__icon-links-wrapper">
                     {/* Product List Wrapper */}
                     <React.Fragment>
                        <NavigationIcon
                           className="nav-bar__product-list-icon"
                           isActive={activeMenu === "PRODUCT_LIST"}
                           onClick={() => handleToggleMenu?.("PRODUCT_LIST")}
                           icon={ListCheckListIcon}
                           number={null}
                        />
                        {activeMenu === "PRODUCT_LIST" && <ProductList />}
                     </React.Fragment>
                     <React.Fragment>
                        <NavigationIcon
                           className="nav-bar__currency-icon"
                           isActive={activeMenu === "CURRENCY"}
                           onClick={() => handleToggleMenu?.("CURRENCY")}
                           icon={CurrencyIcon}
                           number={null}
                        />
                        {activeMenu === "CURRENCY" && <CurrencyList />}
                     </React.Fragment>
                     {/* Notification List Wrapper --pending */}
                     {/* <React.Fragment>
								<NavigationIcon icon={BellIcon} number={3} />
							</React.Fragment> */}
                  </Drawer>
                  <UserMenu />
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(Desktop);
