import React, { useContext } from "react";
// Styles
import "./styles/styles.scss";
// Icons
import InfoIcon from "@icons/InfoIcon.svg";
// Context
import { Context } from "../../context";

const ErrorMessage = () => {
   const { errorMessage } = useContext(Context);
   return (
      <React.Fragment>
         {errorMessage && (
            <div className="text-input__error">
               <InfoIcon className="text-input__error__icon" />
               <p className="text-input__error__message">{errorMessage}</p>
            </div>
         )}
      </React.Fragment>
   );
};

export default React.memo(ErrorMessage);
