import { axios } from "@redux/store";
// Types
import { TPayload } from "../request/types";
import { TError, TMatchesShape } from "../../sliceOld/types";
import { AxiosResponse } from "axios";
import { TMatchOld } from "@features/Product/store/sliceOld/types";
// Utils
import { currencyFormat } from "@utils/currencyFormat";

export const handleGetMatches = async ({
   branchId,
   page,
   perPage,
   currency,
}: TPayload): Promise<{
   items?: {
      id: string;
      matches: Omit<TMatchOld, "isInList">[];
   }[];
   perPage?: number;
   page?: number;
   total?: number;
   error?: TError;
}> => {
   try {
      const res: AxiosResponse<{
         items: TMatchesShape[];
         page: number;
         perPage: number;
         total: number;
      }> = await axios({
         url: `/products/matches/${branchId}?currency=${
            currency?.currency || "USD"
         }&page=${page || 1}&perPage=${perPage || 5}`,
         method: "GET",
      });

      const updatedShape: {
         id: string;
         matches: Omit<TMatchOld, "isInList">[];
      }[] = res.data.items.map((item) => {
         return {
            id: item.clusterId,
            matches: item.items.reduce((a: Omit<TMatchOld, "isInList">[], c, index) => {
               a.push({
                  id: `${c?.clusterId}${index}`,
                  branch: c?.branch.name,
                  branchPartNumber: c?.branch.partNumber,
                  supplier: c?.supplier !== null ? c?.supplier.name : "-",
                  supplierPartNumber: c?.supplier !== null ? c?.supplier.partNumber : "-",
                  price:
                     c?.sell !== null
                        ? currencyFormat(
                             c?.sell.price,
                             currency?.locale as string,
                             currency?.currency as string
                          )
                        : "-",
                  pid: c?.productId,
               });
               return a;
            }, []),
         };
      });

      return {
         items: updatedShape,
         perPage: res.data.perPage,
         page: res.data.page,
         total: res.data.total,
      };
   } catch (err) {
      throw {
         error: err,
      };
   }
};
