import React, { ReactNode } from "react";
// Styles
import "./styles/styles.scss";

interface IProps {
   children: ReactNode;
   className?: string;
}

const Header = ({ children, className = "" }: IProps) => {
   return <div className={`card__header ${className}`}>{children}</div>;
};

export default React.memo(Header);
