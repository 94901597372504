import { useEffect, useRef } from "react";
// Idle timer
import { useIdleTimer } from "react-idle-timer";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { authRequest } from "@features/Auth/store/slice";
// Utils
import { getTimeDifference } from "@utils/getMinuteDifference";
import { authRequestType as reqType } from "@features/Auth/utils/constants";
// Types
import { RootState } from "@redux/rootReducer";

const IdleTimer = () => {
   const timeStamp = useRef<Date>(new Date());
   // Redux methods
   const dispatch = useDispatch();
   const { user, userLastSignedIn } = useSelector((state: RootState) => state.auth);
   // Idle timer

   const onIdle = () => {
      dispatch(
         authRequest({
            type: reqType.SIGN_OUT,
         })
      );
   };

   const onRefreshTokenExpired = () => {
      if (!user?.isSignedIn) return;

      const handleExpired = () => {
         onIdle();
         message("token-expired");
      };

      if (userLastSignedIn) {
         // Then check the difference between currentTime and currentDate if it exceeds 9hours
         if (getTimeDifference(new Date(), new Date(userLastSignedIn)) >= 540) {
            handleExpired();
         }
      }
   };

   const { start, pause, message, reset } = useIdleTimer({
      onIdle,
      timeout: 60000 * 480,
      crossTab: true,
      leaderElection: true,
      onAction: () => {
         /**
          * We will invoke the onRefreshTokenExpired function so that when user do some actions
          * we will check if the refresh token is expired and then directly sign out the user.
          */
         onRefreshTokenExpired();
         /**
          * We will check the time difference between the timeStamp and the currentDate
          * so that even if user's device sleep and the timer is not working properly we can still sign them out
          * if the time difference is an hour.
          */
         if (getTimeDifference(new Date(), timeStamp.current) >= 480) {
            // If the time difference is 8 hour then we will message all the tabs to log out
            message("idle");
            // Then invoke onIdle
            onIdle();
         } else {
            /**
             * When the time difference is not an hour then we will message all the tabs that
             * user is still active.
             */
            message("active");
            // Then update the timeStamp
            timeStamp.current = new Date();
         }
      },
      onMessage: (data: "active" | "idle") => {
         if (data === "active") {
            // When "active" is received we will reset the timer.
            reset();
            // Then update the timeStamp
            timeStamp.current = new Date();
         } else if (data === "idle" || data === "token-expired") {
            onIdle();
         }
      },
   });

   useEffect(() => {
      if (user?.isSignedIn) {
         /**
          * Invoke onRefreshTokenExpired function first so that when the token is expired
          * we will sign out the user directly
          */
         onRefreshTokenExpired();
         // Then Start the timer
         start();
         // Finally Set the timeStamp
         timeStamp.current = new Date();
      } else {
         pause();
      }
   }, [user?.isSignedIn]);

   return <div></div>;
};

export default IdleTimer;
