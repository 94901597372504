import React, { ReactNode } from "react";
// Styles
import "./styles/styles.scss";

export interface IProps {
   children?: ReactNode;
   className?: string;
   [x: string]: unknown;
}

const Header = ({ children, className = "", ...rest }: IProps) => {
   return (
      <div className={`box__header ${className}`} {...rest}>
         {children}
      </div>
   );
};

export default React.memo(Header);
