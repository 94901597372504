import { put, call, takeLatest, SagaReturnType } from "redux-saga/effects";
// Handlers
import { handlePostFeedback } from "../handlers/index";
// Actions
import { feedbackResolved, feedbackRejected } from "../../slice/index";
// Types
import { TFeedbackPayload } from "./types";
// Utils
import { AnyAction } from "@reduxjs/toolkit";

function* postFeedback({ payload }: AnyAction & { payload: TFeedbackPayload }): unknown {
   try {
      const result: SagaReturnType<typeof handlePostFeedback> = yield call(
         handlePostFeedback,
         { shape: payload.shape }
      );
      yield put(feedbackResolved(result));
   } catch (err) {
      yield put(feedbackRejected(err));
   }
}

export default function* (): unknown {
   yield takeLatest("feedback/feedbackRequest", postFeedback);
}
