import { axios, store } from "@redux/store";
// Saga
import { CallEffect, call } from "@redux-saga/core/effects";
// Utils
import { currencyFormat } from "@utils/currencyFormat";
import { stateType } from "@features/Catalog/utils/constants";
// Types
import { TCatalogPayload } from "../request/types";
import { AxiosError, AxiosResponse } from "axios";
import {
   TGetCategoriesResponse,
   TGetProductsResponse,
   TGetBranchesResponse,
   TGetSuppliersResponse,
   TGetWarehousesResponse,
   TExportCatalogRequestResponse,
   TExportCatalogResponse,
} from "./types";
import {
   TCatalogProduct,
   TCategoryProduct,
   TCategoryType,
   TError,
} from "../../slice/types";
import { TStateType } from "@features/Catalog/utils/constants";
import { TFilterShape } from "@features/Search/store/slice/types";
import { RootState } from "@redux/rootReducer";
// Axios
import { default as axiosOrig } from "axios";
// Download URI
import { downloadURI } from "@utils/download";

export function* handleGetCategory({ level, filters }: TCatalogPayload): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TCategoryType;
      error?: TError;
   },
   never
> {
   try {
      const responses: Promise<AxiosResponse>[] = [];

      for (let i = 0; i < (level || 1); i++) {
         responses.push(
            axios({
               url: "/catalog/categories",
               method: "POST",
               payload: {
                  level,
                  filters: {
                     ...filters,
                     ...(filters?.categoryId?.length
                        ? { categoryId: filters?.categoryId[i] }
                        : {}),
                  },
               },
            })
         );
      }

      const res: TGetCategoriesResponse[] = yield call(async () => {
         return await Promise.all(responses);
      });

      return {
         items: res.map((e) => e.data) as unknown as TCategoryType,
         stateType: stateType.category,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         items: [],
         error: error?.response?.data,
         stateType: stateType.category,
      };
   }
}

export function* handleExportCatalogProducts({
   filters,
   currency,
   sort,
}: TCatalogPayload): Generator<
   CallEffect,
   {
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      // Request the export
      const response: TExportCatalogRequestResponse = yield call(async () => {
         return await axios({
            url: `/catalog/products/export?currency=${currency?.currency || "USD"}`,
            payload: {
               filters: {
                  ...filters,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : { categoryId: undefined }),
               },
               sort,
            },
            method: "POST",
         });
      });
      const { requestId } = response.data;

      // Check the status of the export every 2 seconds
      // eslint-disable-next-line no-constant-condition
      while (true) {
         const res: TExportCatalogResponse = yield call(async () => {
            return await axios({
               url: `/exports/${requestId}`,
               method: "GET",
            });
         });
         // If the status is completed then download the file
         if (res.data.status === "completed") {
            downloadURI(res.data.url, "catalogProducts");
            return {
               stateType: stateType.exportProducts,
            };
            break;
         }
         // If the status is failed then throw an error
         if (res.data.status === "failed") {
            throw new AxiosError("Failed to export");
            break;
         }
         // If the status is pending then wait for 2 seconds and check again
         yield call(async () => new Promise((resolve) => setTimeout(resolve, 2000)));
      }
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.exportProducts,
         items: [],
         error: error.response?.data.error,
      };
   }
}

export function* handleGetCatalogProducts({
   page,
   filters,
   currency,
   perPage,
   sort,
}: TCatalogPayload): Generator<
   CallEffect,
   {
      items: TCatalogProduct;
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      const res: TGetProductsResponse = yield call(() => {
         return axios({
            url: `/catalog/products?currency=${currency?.currency || "USD"}`,
            payload: {
               filters: {
                  ...filters,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : {}),
               },
               pagination: {
                  page,
                  perPage,
               },
               sort,
            },
            method: "POST",
            cancelToken: cancelToken.token,
         });
      });
      // Then fetch the branches
      const branches: AxiosResponse<{
         items: TFilterShape[];
      }> = yield call(() => {
         return axios({
            url: "/branches/catalog",
            cacheKey: "catalogBranches",
            shouldExpire: false,
            method: "GET",
         });
      });
      // Get the search state
      const state = JSON.parse(
         JSON.stringify(store.getState().catalog)
      ) as RootState["catalog"];
      // We will update the items and append the branchName
      const updatedShapeProducts =
         res.data?.items.map((item) => {
            const branchName = branches.data?.items?.find(
               (branch) => branch.id === item.branchId
            )?.name;

            item = {
               ...item,
               branchName,
               price: item?.price
                  ? currencyFormat(
                       Number(item?.price),
                       currency?.locale as string,
                       currency?.currency as string
                    )
                  : null,
            };
            return item;
         }) || [];
      // Then concat the previous item to the new result
      const concatinatedItems = state.products.items?.products?.concat(
         updatedShapeProducts
      ) as TCategoryProduct[];

      return {
         stateType: stateType.products,
         items: {
            perPage: perPage as number,
            page: page as number,
            total: res.data?.total,
            /**
             * We will check if page is 1 and if true we will return just the new result
             * if not then we will return the concatinated items.
             *
             * Reason -> because if filter or the category changes we are reseting the page to 1 and we want the
             * fresh new items and discard the old ones.
             */
            products: page === 1 ? updatedShapeProducts : concatinatedItems,
         },
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.products,
         items: [],
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetCatalogBranch(): Generator<
   CallEffect,
   {
      items: TFilterShape[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: TGetBranchesResponse = yield call(() => {
         return axios({
            url: "/branches/catalog",
            cacheKey: "catalogBranches",
            shouldExpire: false,
            method: "GET",
         });
      });

      return {
         stateType: stateType.branch,
         items: res.data.items,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.branch,
         error: error.response?.data?.error,
      };
   }
}

export function* handleGetAccessibleBranches(): Generator<
   CallEffect<AxiosResponse>,
   {
      items: TFilterShape[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: TGetBranchesResponse = yield call(() => {
         return axios({
            url: "/branches/accessible",
            cacheKey: "accessibleBranches",
            method: "GET",
         });
      });

      return {
         stateType: stateType.accessibleBranches,
         items: res.data.items,
      };
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         stateType: stateType.branch,
         error: error.response?.data,
      };
   }
}

export function* handleGetCatalogSuppliers({ filters }: TCatalogPayload): Generator<
   CallEffect,
   {
      items: Omit<TFilterShape, "language" | "country">[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: TGetSuppliersResponse = yield call(() => {
         return axios({
            url: "/catalog/suppliers",
            method: "POST",
            payload: {
               search: {
                  query: "",
               },
               filters: {
                  warehouseIds: filters?.warehouseIds,
                  branchIds: [filters?.branchId],
                  sells: filters?.sells,
                  stocks: filters?.stocks,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : {}),
               },
            },
         });
      });

      return {
         stateType: stateType.suppliers,
         items: res.data.items,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.suppliers,
         error: error.response?.data?.error,
      };
   }
}

export function* handleGetCatalogWarehouses({ filters }: TCatalogPayload): Generator<
   CallEffect,
   {
      items: Omit<TFilterShape, "language" | "country">[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      // Then do the rest
      const res: TGetWarehousesResponse = yield call(() => {
         return axios({
            url: "/catalog/warehouses",
            method: "POST",
            payload: {
               search: {
                  query: "",
               },
               filters: {
                  supplierIds: filters?.supplierIds,
                  branchIds: [filters?.branchId],
                  sells: filters?.sells,
                  stocks: filters?.stocks,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : {}),
               },
            },
         });
      });

      // We will update the shape
      const updatedShape = res.data?.items?.map((e) => {
         return {
            id: e.id,
            name: e.address,
         };
      });

      return {
         items: updatedShape,
         stateType: stateType.warehouses,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.warehouses,
         error: error.response?.data?.error,
      };
   }
}
