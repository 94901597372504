import React from "react";

interface IProps {
   icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
   wrapperClassName?: string;
   iconClassName?: string;
   [x: string]: unknown;
   handleOnClick?(): void;
}

const RightSideIcon: React.FC<IProps> = ({
   icon: Icon,
   wrapperClassName = "",
   iconClassName = "",
   handleOnClick = () => null,
   ...rest
}) => {
   return (
      <div {...rest} className={wrapperClassName} onClick={handleOnClick}>
         <Icon className={iconClassName} />
      </div>
   );
};

export default React.memo(RightSideIcon);
