import React, { useEffect, useMemo } from "react";
// Styles
import "./styles/main.scss";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { bannerRequest } from "@features/Banner/store/slice";
// Utils
import {
   bannerRequestType as reqType,
   stateType,
} from "@features/Banner/utils/constants";
import { status } from "@utils/constants";
// Types
import { RootState } from "@redux/rootReducer";
// Icons
import CloseIcon from "@icons/CloseIcon.svg";
// Hooks
import { useLocalStorage } from "@hooks/useLocalStorage";

interface IProps {
   onChange?: (isActive?: boolean) => void;
}

const Banner = ({ onChange }: IProps) => {
   const [closedBanner, setClosedBanner] = useLocalStorage("closedBanner", "");
   // Redux methods
   const dispatch = useDispatch();
   const { banner } = useSelector((state: RootState) => state.banner);

   const isActive = useMemo<boolean>(() => {
      // We gonna store the conditions inside a variable
      const condition =
         banner.status === status.RESOLVED &&
         banner.items?.id &&
         closedBanner !== banner.items?.id;
      // Then invoke the onChange event props
      onChange?.(condition || false);
      // Then return the condition if the banner is active
      return condition as boolean;
   }, [banner.status, JSON.stringify(banner.items), closedBanner]);

   const handleClose = () => {
      setClosedBanner(banner.items?.id as string);
   };

   useEffect(() => {
      dispatch(
         bannerRequest({
            type: reqType.GET_BANNER,
            stateType: stateType.banner,
         })
      );
   }, []);

   return isActive ? (
      <div
         className={`
         banner
         banner--${typeof banner.items?.type === "string" ? banner.items?.type : ""}
     `}
      >
         <p className="banner__message p-14-med">{banner.items?.content}</p>
         <CloseIcon className="banner__close" onClick={handleClose} />
      </div>
   ) : (
      <React.Fragment></React.Fragment>
   );
};

export default Banner;
