import { axios } from "@redux/store";
import { default as axiosOrig } from "axios";
// Utils
import { stateType } from "../../../utils/constants";
// Types
import { CallEffect, call } from "redux-saga/effects";
import {
   TDownloadUrl,
   TError,
   TMatchesBranch,
   TMatchesItem,
   TMatchesNumberRange,
} from "../../slice/types";
import { TStateType } from "../../../utils/constants";
import { TPayload } from "../request/types";
import {
   TDownloadMatchesResponse,
   TGetBranchResponse,
   TGetMatchesNumberRangeResponse,
   TGetMatchesResponse,
} from "./types";
import { AxiosError } from "axios";

export function* handleGetMatches({
   order,
   currency,
   filters,
   pagination,
   branchId,
}: TPayload): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TMatchesItem;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      // Fetch the transactions
      const res: TGetMatchesResponse = yield call(async () => {
         return axios({
            url: `/products/matches/${branchId}/${currency}`,
            method: "POST",
            payload: {
               filters,
               order,
               pagination,
               currency: currency ?? "USD",
            },
            cancelToken: cancelToken.token,
         });
      });

      return {
         items: {
            matches: res.data.items,
            total: res.data.total,
            page: res.data.page,
            perPage: res.data.perPage,
         },
         stateType: stateType.matches,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.matches,
         error: error.response?.data.error,
         items: {
            matches: [],
            total: 0,
            page: 0,
            perPage: 0,
         },
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleDownloadMatches({
   order,
   currency,
   filters,
   branchId,
}: Omit<TPayload, "pagination">): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TDownloadUrl;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      // Fetch the transactions
      const res: TDownloadMatchesResponse = yield call(async () => {
         return axios({
            url: `/products/matches/download/${branchId}/${currency}`,
            method: "POST",
            payload: {
               filters,
               order,
               currency: currency ?? "USD",
            },
            cancelToken: cancelToken.token,
         });
      });

      return {
         items: {
            url: res.data.url,
         },
         stateType: stateType.download,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.matches,
         error: error.response?.data.error,
         items: {
            matches: [],
            total: 0,
            page: 0,
            perPage: 0,
         },
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetRanges({ currency, branchId }: TPayload): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TMatchesNumberRange;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      // Then fetch the ranges
      const ranges: TGetMatchesNumberRangeResponse = yield call(() => {
         return axios({
            url: `/products/matches/${branchId}/${currency}/filters`,
            method: "POST",
            cancelToken: cancelToken.token,
         });
      });

      return {
         items: ranges.data,
         stateType: stateType.ranges,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.ranges,
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetBranches(): Generator<
   CallEffect,
   {
      stateType: TStateType;
      items?: TMatchesBranch[];
      error?: TError;
   },
   never
> {
   try {
      const branches: TGetBranchResponse = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      // Then update the branches shape
      const branchesUpdatedShape = branches.data.items.map((branch) => {
         return {
            name: branch.name,
            id: branch.id,
         };
      });

      return {
         items: branchesUpdatedShape,
         stateType: stateType.branches,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.branches,
         error: error.response?.data.error,
      };
   }
}
