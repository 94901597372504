import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
import { stateType } from "@features/Product/utils/constants";
// Types
import { IStates, TPayload } from "./types";

const product = createSlice({
   name: "product",
   initialState: {
      product: {
         items: {
            branches: [],
            supplier: {
               id: "",
               name: "",
            },
            sell: {
               id: "",
               name: "",
            },
            description: "",
            id: "",
            imageUrl: "",
            inList: false,
            name: "",
            partNumber: "",
         },
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      categories: {
         items: {
            branch: {
               id: "",
               name: "",
            },
            categories: [],
         },
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      match: {
         items: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      matchOld: {
         items: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      attributes: {
         items: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      document: {
         items: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      stock: {
         items: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
   } as IStates,
   reducers: {
      productRequest: (state, { payload }: TPayload) => {
         // Put back the status of the rest state to idle
         Object.values(stateType).forEach((key) => {
            /**
             * If state status is not pending then we will set it to idle
             * so that we will not reset a pending request.
             */
            if (
               key !== payload.stateType &&
               state[key].status !== status.PENDING &&
               payload.stateType !== "all"
            ) {
               state[key].status = status.IDLE;
            } else {
               // Then update the status and error for the passed stateType
               state[key].status = payload.status ?? status.PENDING;
               state[key].error = {
                  data: {},
               };
            }
         });
      },
      productResolved: (state, { payload }: TPayload) => {
         if (payload.stateType !== "all") {
            state[payload.stateType].items = payload.items;
            state[payload.stateType].status = status.RESOLVED;
            state[payload.stateType].error = {
               data: {},
            };
         }
      },
      productRejected: (state, { payload }: TPayload) => {
         if (payload.stateType !== "all") {
            state[payload.stateType].items =
               payload.items ?? state[payload.stateType].items;
            state[payload.stateType].error = payload.error;
            state[payload.stateType].status = status.REJECTED;
         }
      },
   },
});

export const { productRequest, productResolved, productRejected } = product.actions;
export default product.reducer;
