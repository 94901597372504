export const authRequestType = {
   SIGN_IN: "SIGN_IN",
   CHANGE_PASSWORD: "CHANGE_PASSWORD",
   VALIDATE_MFA_TOTP_CODE: "VALIDATE_MFA_TOTP_CODE",
   VALIDATE_SIGN_IN_TOTP_CODE: "VALIDATE_SIGN_IN_TOTP_CODE",
   SIGN_OUT: "SIGN_OUT",
   GET_SESSION: "GET_SESSION",
   UPDATE_USER: "UPDATE_USER",
};

export const authStatusCode = {
   // Sign in
   SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
   SIGN_IN_PENDING: "SIGN_IN_PENDING",
   SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
   // Sign in
   SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
   SIGN_UP_PENDING: "SIGN_UP_PENDING",
   SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
   // Sign in status codes
   SIGN_IN_MFA_SETUP: "SIGN_IN_MFA_SETUP",
   SIGN_IN_ASSOCIATE_SECRET_CODE: "SIGN_IN_ASSOCIATE_SECRET_CODE",
   SIGN_IN_TOTP_REQUIRED: "SIGN_IN_TOTP_REQUIRED",
   // Change password status codes
   NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
   CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
   CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
   CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
   CHANGE_PASSWORD_MFA_SETUP: "CHANGE_PASSWORD_MFA_SETUP",
   CHANGE_PASSWORD_ASSOCIATE_SECRET_CODE: "CHANGE_PASSWORD_ASSOCIATE_SECRET_CODE",
   // Session status codes
   GET_SESSION_SUCCESS: "GET_SESSION_SUCCESS",
   GET_SESSION_FAILURE: "GET_SESSION_FAILURE",
   GET_SESSION_PENDING: "GET_SESSION_PENDING",
   // Validate TOTP status codes
   VALIDATE_TOTP_PENDING: "VALIDATE_TOTP_PENDING",
   VALIDATE_TOTP_SUCCESS: "VALIDATE_TOTP_SUCCESS",
   VALIDATE_TOTP_FAILURE: "VALIDATE_TOTP_FAILURE",
   // Validate Mfa status codes
   VALIDATE_MFA_PENDING: "VALIDATE_MFA_PENDING",
   VALIDATE_MFA_SUCCESS: "VALIDATE_MFA_SUCCESS",
   VALIDATE_MFA_FAILURE: "VALIDATE_MFA_FAILURE",
   // Sign out status codes
   SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
   SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
} as const;

export type TAuthStatusCodeType = (typeof authStatusCode)[keyof typeof authStatusCode];
