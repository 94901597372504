// Store
import { axiosInstance } from "@redux/store";
// Types
import { AxiosError, AxiosResponse } from "axios";

export default () => {
   const validateAndRetry = (response: AxiosResponse) => {
      const savedVersion = localStorage.getItem("savedVersion");
      const responseVersion = response.headers["app-version"] as string;

      if ((!savedVersion || responseVersion !== savedVersion) && responseVersion) {
         localStorage.setItem("savedVersion", responseVersion);
         window.location.reload();
      }
   };
   // Response
   axiosInstance.interceptors.response.use(
      (response) => {
         validateAndRetry(response);
         return response;
      },
      (err: AxiosError) => {
         if (err.response) {
            validateAndRetry(err?.response);
         }
         return Promise.reject(err);
      }
   );
};
