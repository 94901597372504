import { axios, store } from "@redux/store";
// Types
import { TError, TFilterPrice, TFilterShape, TSearchProduct } from "../../slice/types";
import { TSearchResultPayload } from "../request/types";
import { AxiosError, default as axiosOrig } from "axios";
import { TStateType } from "@features/Search/utils/constants";
// Saga
import { CallEffect, PutEffect, call } from "redux-saga/effects";
// Types
import {
   TGetPriceResponse,
   TGetProductResponse,
   TGetSuppliersResponse,
   TGetWarehousesResponse,
} from "./types";
// Utils
import { stateType } from "@features/Search/utils/constants";
import { currencyFormat } from "@utils/currencyFormat";
import { RootState } from "@redux/rootReducer";

export function* handleGetSearchResult({
   search,
   page,
   perPage,
   filters,
   currency,
}: TSearchResultPayload): Generator<
   CallEffect | PutEffect,
   {
      items?: {
         products: TSearchProduct[];
         total: number;
      };
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      /**
       * As the search endpoint gives error when one of these (suppliers, warehouses, branch)
       * arrays is empty we will conditionally add them in the filters.
       */
      const conditionallyAddedMember = (arr: unknown[] = [], key: string) => {
         return {
            ...(arr && arr.join("").toString() && arr.length ? { [key]: arr } : {}),
         };
      };

      // Temporarily removed the fields, sort and filters
      const result: TGetProductResponse = yield call(() => {
         return axios({
            url: `/search/products?currency=${currency?.currency || "USD"}`,
            method: "POST",
            cancelToken: cancelToken.token,
            retry: 2,
            payload: {
               search: {
                  query: search,
               },
               filters: {
                  // priceMin: filters?.priceMin,
                  // priceMax: filters?.priceMax,
                  ...conditionallyAddedMember(filters?.branches, "branchIds"),
                  ...conditionallyAddedMember(filters?.suppliers, "supplierIds"),
                  ...conditionallyAddedMember(filters?.warehouses, "warehouseIds"),
                  stocks: filters?.stocks,
                  sells: filters?.sells,
               },
               // sort: {
               //    field: "price",
               //    direction: filters?.sortPrice || "desc",
               // },
               pagination: {
                  page,
                  perPage,
               },
            },
         });
      });
      // Get the search state
      const state = JSON.parse(
         JSON.stringify(store.getState().searchResult)
      ) as RootState["searchResult"];
      // We will update the items and append the branchName
      const updatedShapeItems =
         result?.data?.items?.map((item) => {
            // Then append it to the object
            item = {
               ...item,
               price: item?.price
                  ? currencyFormat(
                       Number(item?.price),
                       currency?.locale as string,
                       currency?.currency as string
                    )
                  : null,
            };

            return item;
         }) || [];
      // Then concat the previous item to the new result
      const concatinatedItems = state.search.items?.products?.concat(
         updatedShapeItems
      ) as TSearchProduct[];
      // Then we will return the updated shape of the search result item
      return {
         items: {
            /**
             * We will check if page is 1 and if true we will return just the new result
             * if not then we will return the concatinated items.
             *
             * Reason -> because if filter changes we are reseting the page to 1 and we want the
             * fresh new items and discard the old ones.
             */
            products: page === 1 ? updatedShapeItems : concatinatedItems,
            total: result.data.total,
         },
         stateType: stateType.search,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.search,
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetFilterBranches(): Generator<
   CallEffect,
   {
      items?: TFilterShape[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: TGetSuppliersResponse = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      return {
         items: res.data["items"],
         stateType: stateType.branch,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.branch,
      };
   }
}

export function* handleGetFilterSupplier({
   search,
   warehouses,
   branches,
   sells,
   stocks,
}: TSearchResultPayload): Generator<
   CallEffect,
   {
      items?: Omit<TFilterShape, "language" | "country">[];
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const res: TGetSuppliersResponse = yield call(() => {
         return axios({
            url: "/search/suppliers",
            method: "POST",
            payload: {
               search: {
                  query: search,
               },
               filters: {
                  warehouseIds: warehouses,
                  branchIds: branches?.join("") ? branches : undefined,
                  sells,
                  stocks,
               },
            },
         });
      });

      return {
         items: res.data?.["items"],
         stateType: stateType.suppliers,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.suppliers,
      };
   }
}

export function* handleGetFilterWarehouses({
   search,
   suppliers,
   branches,
   sells,
   stocks,
}: TSearchResultPayload): Generator<
   CallEffect,
   {
      items?: Omit<TFilterShape, "language" | "country">[];
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const res: TGetWarehousesResponse = yield call(() => {
         return axios({
            url: "/search/warehouses",
            method: "POST",
            payload: {
               search: {
                  query: search,
               },
               filters: {
                  supplierIds: suppliers,
                  branchIds: branches?.join("") ? branches : undefined,
                  sells,
                  stocks,
               },
            },
         });
      });

      const updatedShape = res?.data?.items?.map((warehouse) => {
         return {
            id: warehouse?.id,
            name: warehouse?.address,
         };
      });

      return {
         items: updatedShape,
         stateType: stateType.warehouses,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.warehouses,
      };
   }
}

export function* handleGetFilterPrice(): Generator<
   CallEffect,
   {
      items?: TFilterPrice;
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const res: TGetPriceResponse = yield call(() => {
         return axios({
            url: "/products/prices",
            method: "GET",
         });
      });

      return {
         items: res.data,
         stateType: stateType.price,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.price,
      };
   }
}
