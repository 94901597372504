import { takeEvery, takeLatest, put, call, SagaReturnType } from "redux-saga/effects";
// Utils
import { catalogRequestType as reqType } from "@features/Catalog/utils/constants";
// Types
import { AnyAction } from "@reduxjs/toolkit";
import { TCatalogPayload } from "./types";
// Actiosn
import { catalogRejected, catalogResolved } from "../../slice";
// Handlers
import {
   handleExportCatalogProducts,
   handleGetAccessibleBranches,
   handleGetCatalogBranch,
   handleGetCatalogProducts,
   handleGetCatalogSuppliers,
   handleGetCatalogWarehouses,
   handleGetCategory,
} from "../handlers";

function* exportCatalogProducts({
   filters,
   total,
   currency,
   sort,
}: AnyAction & TCatalogPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleExportCatalogProducts> = yield call(
         handleExportCatalogProducts,
         { filters, total, currency, sort }
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleExportCatalogProducts>;
      yield put(catalogRejected(error));
   }
}

function* getCategory({ filters, level }: AnyAction & TCatalogPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetCategory> = yield call(
         handleGetCategory,
         { filters, level }
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetCategory>;
      yield put(catalogRejected(error));
   }
}

function* getCatalogProducts({
   page,
   prevItems,
   filters,
   currency,
   perPage,
   sort,
}: AnyAction & TCatalogPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetCatalogProducts> = yield call(
         handleGetCatalogProducts,
         { page, prevItems, filters, currency, perPage, sort }
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetCatalogProducts>;
      yield put(catalogRejected(error));
   }
}

function* getCatalogBranch(): unknown {
   try {
      const res: SagaReturnType<typeof handleGetCatalogBranch> = yield call(
         handleGetCatalogBranch
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetCatalogBranch>;
      yield put(catalogRejected(error));
   }
}

function* getCatalogAccessibleBranches(): unknown {
   try {
      const res: SagaReturnType<typeof handleGetAccessibleBranches> = yield call(
         handleGetAccessibleBranches
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetAccessibleBranches>;
      yield put(catalogRejected(error));
   }
}

function* getCatalogSupplier({ filters }: TCatalogPayload & AnyAction): unknown {
   try {
      const res: SagaReturnType<typeof handleGetCatalogSuppliers> = yield call(
         handleGetCatalogSuppliers,
         { filters }
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetCatalogSuppliers>;
      yield put(catalogRejected(error));
   }
}

function* getCatalogWarehouses({ filters }: TCatalogPayload & AnyAction): unknown {
   try {
      const res: SagaReturnType<typeof handleGetCatalogWarehouses> = yield call(
         handleGetCatalogWarehouses,
         { filters }
      );
      yield put(catalogResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetCatalogWarehouses>;
      yield put(catalogRejected(error));
   }
}

export default function* () {
   // Request's
   yield takeEvery("catalog/catalogRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(reqType.GET_CATALOG_BRANCHES, getCatalogBranch);
   yield takeLatest(
      reqType.GET_CATALOG_ACCESSIBLE_BRANCHES,
      getCatalogAccessibleBranches
   );
   yield takeLatest(reqType.GET_CATALOG_SUPPLIERS, getCatalogSupplier);
   yield takeLatest(reqType.GET_CATALOG_WAREHOUSES, getCatalogWarehouses);
   yield takeLatest(reqType.GET_CATALOG_CATEGORY, getCategory);
   yield takeLatest(reqType.EXPORT_CATALOG_PRODUCTS, exportCatalogProducts);
   yield takeLatest(reqType.GET_CATALOG_PRODUCTS, getCatalogProducts);
}
