import React, { ReactNode } from "react";
// Styles
import "./styles/styles.scss";

interface IProps {
   children: ReactNode;
   className?: string;
}

const Content = ({ children, className = "" }: IProps) => {
   return <div className={`card__content ${className}`}>{children}</div>;
};

export default React.memo(Content);
