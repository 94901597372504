export const transactionsRequestType = {
   GET_TRANSACTIONS: "GET_TRANSACTIONS",
   GET_TRANSACTIONS_BRANCHES: "GET_TRANSACTIONS_BRANCHES",
   GET_TRANSACTIONS_RANGES: "GET_TRANSACTIONS_RANGES",
   DOWNLOAD_TRANSACTIONS: "DOWNLOAD_TRANSACTIONS",
} as const;

export type TRequestType =
   (typeof transactionsRequestType)[keyof typeof transactionsRequestType];

export const stateType = {
   transactions: "transactions",
   branches: "branches",
   ranges: "ranges",
   download: "download",
} as const;

export type TStateType = (typeof stateType)[keyof typeof stateType];
