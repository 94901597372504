import { takeEvery, call, put, SagaReturnType, takeLeading } from "redux-saga/effects";
// Handlers
import {
   handleGetUsers,
   handleRemoveUser,
   handleCreateUser,
   handleGetUser,
   handleEditUser,
   handleResetPassword,
   handleGetUserLogs,
   handleGetBranches,
} from "../handlers";
// Actions
import { accountManagementRejected, accountManagementResolved } from "../../slice";
// Utils
import { accountManagementRequestType as reqType } from "../../../utils/constants";
// Types
import { AnyAction } from "@reduxjs/toolkit";
import { TAccountManagementPayload } from "./types";

function* editUser({ username, editUserData }: TAccountManagementPayload) {
   try {
      const res: SagaReturnType<typeof handleEditUser> = yield call(handleEditUser, {
         username,
         editUserData,
      });

      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetUser>;
      yield put(accountManagementRejected(error));
   }
}

function* getUser({ username }: AnyAction | TAccountManagementPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetUser> = yield call(handleGetUser, {
         username,
      });
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetUser>;
      yield put(accountManagementRejected(error));
   }
}

function* getUserLogs({ username }: AnyAction | TAccountManagementPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetUserLogs> = yield call(
         handleGetUserLogs,
         {
            username,
         }
      );
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetUserLogs>;
      yield put(accountManagementRejected(error));
   }
}

function* getUsers({
   currentUserId,
   page,
   perPage,
}: AnyAction | TAccountManagementPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleGetUsers> = yield call(handleGetUsers, {
         currentUserId,
         page,
         perPage,
      });
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetUsers>;
      yield put(accountManagementRejected(error));
   }
}

function* createUser({
   user,
   currentUserId,
}: AnyAction | TAccountManagementPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleCreateUser> = yield call(handleCreateUser, {
         user,
         currentUserId,
      });
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleCreateUser>;
      yield put(accountManagementRejected(error));
   }
}

function* removeUser({ username }: TAccountManagementPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleRemoveUser> = yield call(handleRemoveUser, {
         username,
      });
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleCreateUser>;
      yield put(accountManagementRejected(error));
   }
}

function* resetPassword({ username }: TAccountManagementPayload): unknown {
   try {
      const res: SagaReturnType<typeof handleResetPassword> = yield call(
         handleResetPassword,
         { username }
      );
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleCreateUser>;
      yield put(accountManagementRejected(error));
   }
}

function* getBranches({}): unknown {
   try {
      const res: SagaReturnType<typeof handleGetBranches> = yield call(handleGetBranches);
      yield put(accountManagementResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetBranches>;
      yield put(accountManagementRejected(error));
   }
}

export default function* (): unknown {
   // Request's
   yield takeEvery(
      "accountManagement/accountManagementRequest",
      function* ({ payload }: AnyAction) {
         yield put({ type: payload.type, ...payload });
      }
   );
   // Actions
   yield takeLeading(reqType.CREATE_USER, createUser);
   yield takeLeading(reqType.GET_BRANCH, getBranches);
   yield takeLeading(reqType.GET_USER, getUser);
   yield takeLeading(reqType.GET_USERS, getUsers);
   yield takeLeading(reqType.REMOVE_USER, removeUser);
   yield takeLeading(reqType.EDIT_USER, editUser);
   yield takeLeading(reqType.RESET_PASSWORD, resetPassword);
   yield takeLeading(reqType.GET_USER_LOGS, getUserLogs);
}
