// Redux
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
// Persist
import { persistStore, persistReducer } from "redux-persist";
import { getPersistConfig } from "redux-deep-persist";
// Saga
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./rootSaga";
// Local forage
import localforage from "localforage";
// Axios
import Axios, { AxiosResponse, CancelToken } from "axios";
import axiosRetry from "axios-retry";
// Interceptos
import tokenInterceptor from "./interceptors/token";
import versionInterceptor from "./interceptors/appVersion";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Then configure the persist config
const persistConfig = getPersistConfig({
   key: "etc",
   storage: localforage,
   rootReducer,
   blacklist: [
      "auth.code",
      "auth.status",
      "searchResult.search",
      "searchResult.filters",
      "product",
      "feedback",
      "productList",
      "accountManagement",
      "catalog",
      "matches",
      "transactions",
   ],
});

// Then wrap the root reducer with persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Then export the store
export const store = configureStore({
   reducer: persistedReducer,
   middleware: [sagaMiddleware],
});

// Then create an axios instance
export const axiosInstance = Axios.create({
   baseURL: import.meta.env.VITE_REACT_APP_BASE_URL as string,
});

// Then create axios method that would cache the response
type TAxios = {
   url: string;
   method: TMethods;
   payload?: unknown;
   cacheKey?: string;
   removeCacheKey?: string;
   shouldExpire?: boolean;
   cancelToken?: CancelToken | null;
   retry?: number;
};

type TMethods = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type TCache = {
   [x: string]: {
      data: AxiosResponse;
      dateCreated: Date;
   };
};

const cache: TCache = {};

export const axios = async ({
   url,
   method,
   payload,
   cacheKey = "",
   removeCacheKey = "",
   shouldExpire = true,
   cancelToken = null,
   retry = 1,
}: // retry = 1,
TAxios): Promise<AxiosResponse> => {
   // We will check if "removeCache" is  passed so that we will remove a cache key
   if (removeCacheKey) {
      const removedKey: string[] = Object.keys(cache).filter((e: string) =>
         e.includes(removeCacheKey)
      );
      if (removedKey) {
         removedKey?.forEach((key) => {
            delete cache[key];
         });
      }
   }

   // We will check if the key is already on the cache
   if (cache?.[cacheKey] && cacheKey) {
      // Then check if cache dateCreate is not greater than 5 seconds
      const isCacheExpired = (): boolean => {
         const time = new Date().getTime();
         const cacheTime = cache[cacheKey]?.dateCreated.getTime();

         if (cache[cacheKey] && !shouldExpire) {
            return false;
         }

         return (time - cacheTime) / 1000 > 5;
      };

      if (!isCacheExpired()) {
         return cache[cacheKey].data;
      } else {
         delete cache[cacheKey];
      }
   }

   /**
    * We will check if we passed a retry number so that when request failed
    * we will retry the request again with the specific times.
    */
   if (retry >= 1) {
      axiosRetry(axiosInstance, {
         retries: retry,
         retryCondition: (error) => {
            if (error) {
               return true;
            }

            return false;
         },
      });
   }

   // If key is not on the cache we will do the request using axios
   const request = await axiosInstance({
      url,
      method,
      data: payload,
      ...(cancelToken ? { cancelToken: cancelToken } : {}),
   });

   if (cacheKey) {
      // Remove the old cache
      delete cache?.[cacheKey];
      // Then cache the new data
      cache[cacheKey] = {
         data: request,
         dateCreated: new Date(),
      };
   }

   return request;
};

// Then we will invoke our Interceptors here
tokenInterceptor();
versionInterceptor();

// Then export persist store
export const persistor = persistStore(store);

// Finally run the saga with the root saga
sagaMiddleware.run(rootSaga);
