import React from "react";
// Styles
import "./styles/styles.scss";
// Router
import { Link } from "react-router-dom";

type TLinks = {
   value: string;
   path: string;
};

interface IProps {
   links?: TLinks[];
}
const NavigationLinks: React.FC<IProps> = ({ links = [] }) => {
   return (
      <div className="nav-bar__links">
         {links?.map(({ value, path }) => (
            <Link
               key={`${value}-${path}`}
               className="p-16-med nav-bar__links__link mr-4"
               to={path}
            >
               {value}
            </Link>
         ))}
      </div>
   );
};

export default React.memo(NavigationLinks);
