import React, { ReactNode } from "react";
// Components
import Header from "./subComponents/Header";
import Content from "./subComponents/Content";
import Footer from "./subComponents/Footer";
// Styles
import "./styles/main.scss";

export interface IProps {
   /**
    * - <Card.Header /> -> Wrap your header with this.
    * - <Card.Content /> -> Wrap your content or description with this.
    * - <Card.Footer /> -> Wrap your footer with this.
    */
   children?: ReactNode;
   /**
    * To reuse and  to customize the card on other component you can pass a className into this component.
    */
   className?: string;
   /**
    * If you want the card to take the width of 100%
    */
   isFluid?: boolean;
   [x: string]: unknown;
}

const Card = ({ children, className = "", isFluid = false, ...rest }: IProps) => {
   return (
      <div
         className={`
			card
			card--${isFluid ? "fluid" : ""}
			${className}
			`}
         data-testid="card"
         {...rest}
      >
         {children}
      </div>
   );
};

// Sub Components
Card.Header = Header;
Card.Content = Content;
Card.Footer = Footer;

export default Card;
