import { memo, useEffect, useMemo } from "react";
// Router
import { Outlet, Navigate, useParams } from "react-router-dom";
// Redux
import { RootState } from "@redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "@layouts/LoadingOverlay";
import { catalogRequest } from "@features/Catalog/store/slice";
import { catalogRequestType, stateType } from "@features/Catalog/utils/constants";

const PermissionRoute = () => {
   const { user } = useSelector((state: RootState) => state.auth);
   const { accessibleBranches } = useSelector((state: RootState) => state.catalog);

   const dispatch = useDispatch();

   const { branch } = useParams();

   const shouldPass = useMemo<boolean>(() => {
      const [branchId] = (branch?.split("~") as [string, string]) || [];
      if (accessibleBranches.items?.map((item) => item.id).includes(branchId)) {
         return true;
      }
      return false;
   }, [JSON.stringify(user), JSON.stringify(accessibleBranches.items)]);

   useEffect(() => {
      if (!accessibleBranches.items?.length) {
         dispatch(
            catalogRequest({
               type: catalogRequestType.GET_CATALOG_ACCESSIBLE_BRANCHES,
               stateType: stateType.accessibleBranches,
            })
         );
      }
   }, []);

   if (!accessibleBranches.items?.length) {
      return <LoadingOverlay />;
   }

   return shouldPass ? <Outlet /> : <Navigate to="/" />;
};

export default memo(PermissionRoute);
