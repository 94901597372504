import { createSlice } from "@reduxjs/toolkit";
// Types
import { IStates, TEmailItem, TPayloadType } from "./types";
// Utils
import { status } from "@utils/constants";

const emailBuyer = createSlice({
   name: "emailBuyer",
   initialState: {
      requests: [],
   } as IStates,
   reducers: {
      emailBuyerReset: (state) => {
         state.requests = [];
      },
      emailBuyerUpdateItems: (state, { payload }) => {
         state.requests = payload.requests;
      },
      emailBuyerRequest: (state, {}) => {
         // We will set all the items to status pending
         state.requests = state.requests?.map((request) => {
            return {
               ...request,
               status: status.PENDING,
            };
         });
      },
      emailBuyerResolved: (state, { payload }: TPayloadType) => {
         state.requests = payload.requests as TEmailItem[];
      },
      // We do not need a reject reducer
   },
});

export const {
   emailBuyerReset,
   emailBuyerUpdateItems,
   emailBuyerRequest,
   emailBuyerResolved,
} = emailBuyer.actions;
export default emailBuyer.reducer;
