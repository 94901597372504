/* eslint-disable */
// Aws
import {
   CognitoUser,
   AuthenticationDetails,
   CognitoUserSession,
} from "amazon-cognito-identity-js";
// Configs
import Pool from "../../../../../configs/pool";
// Redux
import { axios, store } from "@redux/store";
// Utils
import { authStatusCode as statusCode } from "../../../utils/constants";
// Types
import { AxiosResponse } from "axios";
import { TUser as TAccountManagementUser } from "@features/AccountManagement/store/slice/types";
import { IStates as IAuthStates, TUser } from "../../slice/types";
import {
   TChangePasswordPayload,
   TGetSessionPayload,
   TSignInPayload,
   TValidateSignInTotpPayload,
   TValidateTotpPayload,
} from "../request/types";
import { TFilterShape } from "@features/Search/store/slice/types";
import { TAuthStatusCodeType } from "../../../utils/constants";

const authenticate = async (
   Username: string,
   Password: string
): Promise<{
   user?: unknown;
   code: TAuthStatusCodeType;
   data: unknown;
   username?: string;
   userLastSignedIn?: Date;
}> => {
   return new Promise((resolve, reject) => {
      // Fetch the cognito user data for the current user
      const user = new CognitoUser({ Username, Pool });
      // Store username and password in authDetails variable
      const authDetails = new AuthenticationDetails({
         Username,
         Password,
      });
      // Authenticate the username and password typed
      user.authenticateUser(authDetails, {
         onSuccess: (data: CognitoUserSession) => {
            resolve({
               code: statusCode.SIGN_IN_SUCCESS,
               data: { ...data },
               username: data.getIdToken()?.payload?.username,
               userLastSignedIn: new Date(),
            });
         },
         onFailure: (err) => {
            reject({
               user,
               code: statusCode.SIGN_IN_FAILURE,
               data: {
                  error: err.message,
               },
            });
         },
         newPasswordRequired: (data) => {
            resolve({
               user,
               code: statusCode.NEW_PASSWORD_REQUIRED,
               data: { ...data },
            });
         },
         mfaSetup: function () {
            // @ts-ignore
            user?.associateSoftwareToken(this);
         },
         totpRequired: function () {
            resolve({
               user,
               code: statusCode.SIGN_IN_TOTP_REQUIRED,
               data: {
                  email: Username,
               },
            });
         },

         // @ts-ignore: as there was no solution found.
         associateSecretCode: function (secretCode: string) {
            resolve({
               user,
               code: statusCode.SIGN_IN_MFA_SETUP,
               data: {
                  secretCode,
                  email: Username,
               },
            });
         },
      });
   });
};

export const handleGetSession = async ({
   shouldUpdateToken,
}: TGetSessionPayload): Promise<{
   code: TAuthStatusCodeType;
   data: unknown;
   user?: unknown;
   shouldUpdateUser?: boolean;
}> => {
   return new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
         // Get session
         user.getSession(
            // As we do not know the type of the session then we will give a type of any to it.
            async (err: { message: unknown }, session: any) => {
               if (err) {
                  reject({
                     code: statusCode.GET_SESSION_FAILURE,
                     user: {},
                     data: {
                        error: err.message,
                     },
                  });
               } else {
                  const resolvedObj = {
                     code: statusCode.GET_SESSION_PENDING,
                     data: session,
                     shouldUpdateUser: true,
                  };
                  // We will check if "shouldUpdateToken" is true then we will fetch another session with new idToken
                  if (shouldUpdateToken) {
                     const refreshToken = session.getRefreshToken();
                     user.refreshSession(
                        refreshToken,
                        async (err, refreshedSesssion: CognitoUserSession) => {
                           if (!err) {
                              resolve({
                                 ...resolvedObj,
                                 code: statusCode.GET_SESSION_SUCCESS,
                                 shouldUpdateUser: false,
                                 data: refreshedSesssion,
                              });
                           } else {
                              // If refresh token is also expired we will log out the user
                              // Then reject the promise
                              reject({
                                 code: statusCode.GET_SESSION_FAILURE,
                                 user: {},
                                 data: {
                                    error: err.message,
                                 },
                              });
                           }
                        }
                     );
                  } else {
                     resolve(resolvedObj);
                  }
               }
            }
         );
      } else {
         reject({
            code: statusCode.GET_SESSION_FAILURE,
            user: {},
            data: {
               error: "No session found!",
            },
         });
      }
   });
};

export const handleChangePassword = async ({
   cogniUser,
   newPassword,
   username,
}: TChangePasswordPayload): Promise<{
   user?: CognitoUser;
   code: TAuthStatusCodeType;
   data?: unknown;
}> => {
   return new Promise((resolve, reject) => {
      cogniUser.completeNewPasswordChallenge(
         newPassword,
         {},
         {
            onSuccess: (result: object) => {
               resolve({
                  code: statusCode.CHANGE_PASSWORD_SUCCESS,
                  data: { ...result },
               });
            },
            onFailure: (err: { message: unknown }) => {
               reject({
                  code: statusCode.CHANGE_PASSWORD_FAILURE,
                  data: { error: err.message },
               });
            },
            totpRequired: function () {
               resolve({
                  code: statusCode.CHANGE_PASSWORD_SUCCESS,
               });
            },
            mfaSetup: function () {
               /*============================================================================
					# Instead of doing the mfa we will resolve the promise and redirect the user back 
					# to sign-in and let them do the mfa there.
					==============================================================================*/
               // @ts-ignore
               cogniUser?.associateSoftwareToken(this);
               // Commented out so that we can bring it back if needed.
               // cogniUser.associateSoftwareToken(this);
               // store.dispatch(authUpdateCode(statusCode.CHANGE_PASSWORD_MFA_SETUP));
            },
            // @ts-ignore: as there was no solution found.
            associateSecretCode: function (secretCode: string) {
               resolve({
                  user: cogniUser,
                  code: statusCode.CHANGE_PASSWORD_MFA_SETUP,
                  data: {
                     secretCode,
                     email: username,
                  },
               });
            },
         }
      );
   });
};

export const handleValidateSignInTotpCode = async ({
   cogniUser,
   totpCode,
}: TValidateSignInTotpPayload): Promise<{
   code: TAuthStatusCodeType;
   data: unknown;
   username?: string;
   userLastSignedIn?: Date;
}> => {
   return new Promise((resolve, reject) => {
      cogniUser.sendMFACode(
         totpCode,
         {
            onSuccess: (res) => {
               resolve({
                  code: statusCode.VALIDATE_TOTP_SUCCESS,
                  data: res,
                  username: res.getIdToken()?.payload?.username,
                  userLastSignedIn: new Date(),
               });
            },
            onFailure: (err: { name: string }) => {
               reject({
                  code: statusCode.VALIDATE_TOTP_FAILURE,
                  data: {
                     error: err.name || "",
                  },
               });
            },
         },
         "SOFTWARE_TOKEN_MFA"
      );
   });
};

export const handleValidateMfaTotpCode = async ({
   cogniUser,
   totpCode,
}: TValidateTotpPayload): Promise<{
   code: TAuthStatusCodeType;
   data: unknown;
   userLastSignedIn?: Date;
}> => {
   return new Promise((resolve, reject) => {
      // Then retrieve the user from the auth state
      const authState: IAuthStates = store.getState().auth;
      // Finally verify the provided otp by them
      cogniUser.verifySoftwareToken(totpCode, "My TOTP device", {
         onSuccess: (session: CognitoUserSession) => {
            resolve({
               code: statusCode.VALIDATE_MFA_SUCCESS,
               data: { ...session },
               userLastSignedIn: new Date(),
            });
         },
         onFailure: (err: { name: string }) => {
            reject({
               code: statusCode.VALIDATE_MFA_FAILURE,
               data: {
                  error: err.name || "",
                  secretCode: authState?.data?.secretCode,
               },
            });
         },
      });
   });
};

export const handleUpdateUser = async (): Promise<{
   code?: TAuthStatusCodeType;
   user?: TUser;
   error?: unknown;
}> => {
   try {
      const user: AxiosResponse<TAccountManagementUser> = await axios({
         url: `/users/self/details`,
         method: "GET",
      });
      const branch: AxiosResponse<{
         items: TFilterShape[];
      }> = await axios({
         url: "/branches",
         cacheKey: "branches",
         shouldExpire: false,
         method: "GET",
      });

      return {
         code: statusCode.GET_SESSION_SUCCESS,
         user: {
            isSignedIn: true,
            username: user.data.sub,
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            branch: user.data.branchId,
            branchName: branch.data.items?.find(
               (branch) => branch.id === user.data.branchId
            )?.name,
            isAdmin: user.data.role === "admin",
            email: user.data.email,
         },
      };
   } catch (err) {
      throw {
         error: err,
      };
   }
};

export const handleSignOut = async (): Promise<boolean> => {
   try {
      const user = Pool.getCurrentUser();
      // Then sign out
      user?.signOut();
      return true;
   } catch (err) {
      return false;
   }
};

export const handleSignIn = async ({
   Username,
   Password,
}: TSignInPayload): Promise<unknown> => {
   return new Promise(async (resolve, reject) => {
      try {
         const result = await authenticate(Username, Password);
         resolve(result);
      } catch (err) {
         reject(err);
      }
   });
};
