import React, { createContext, ReactNode, useState } from "react";
// React uuid
import uuid from "react-uuid";

export type TToastType = "success" | "error" | "warning" | "info";
export type TToastState = "solid" | "outline";
export type TToastPushToast = (toast: TToast) => void;
export type TToastRemoveToast = (id: string) => void;

export type TToast = {
   id?: string;
   message?: string;
   type?: TToastType;
   state?: TToastState;
};

export type TToastContextValues = {
   listOfToast: TToast[];
   pushToast: TToastPushToast;
   removeToast: TToastRemoveToast;
};

interface IProps {
   children: ReactNode;
}

/**
 * We specify the context name here because we will not just
 * use this within it's subcomponent but globally so we need to be specific for this context.
 */
export const ToastContext = createContext<TToastContextValues>({
   pushToast: () => null,
   listOfToast: [],
   removeToast: () => null,
});

const ToastProvider = ({ children }: IProps) => {
   const [listOfToast, setListOfToasts] = useState<TToast[]>([]);

   const pushToast = (toast: TToast): void => {
      const toastId = `toast-${uuid()}`;
      const toastObject = {
         ...toast,
         id: toastId,
      };

      setListOfToasts((prev) => [toastObject, ...prev]);

      // Auto remove the toast
      setTimeout(() => {
         setListOfToasts((prev) => prev.filter((toast) => toast.id !== toastId));
      }, 3500);
   };

   /**
    * This function remove toast with id.
    */
   const removeToast = (id: string): void => {
      setListOfToasts((prev) => prev.filter((toast) => toast.id !== id));
   };

   return (
      <ToastContext.Provider value={{ listOfToast, pushToast, removeToast }}>
         {children}
      </ToastContext.Provider>
   );
};

export default ToastProvider;
