export const currencyFormat = (
   number: number,
   locale: string,
   currency: string,
   // when the passed value is invalid
   fallback?: string
): string => {
   const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
   });

   if (typeof number === "number" && number) {
      return formatter.format(number);
   } else {
      return fallback as string;
   }
};
