import React, { useContext } from "react";
// Icons
import LoadingIcon from "@icons/LoadingIcon.svg";
// Context
import { Context } from "../../context";
// Styles
import "./styles/styles.scss";

interface IProps {
   icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
   [x: string]: unknown;
}

const RightSideIcon: React.FC<IProps> = ({ icon: Icon, ...rest }) => {
   const { isLoading } = useContext(Context);
   return (
      <div className="primary-button__right-icon" {...rest}>
         {!isLoading ? (
            <Icon className="primary-button__right-icon__icon" />
         ) : (
            <LoadingIcon className="primary-button__right-icon__icon" />
         )}
      </div>
   );
};

export default React.memo(RightSideIcon);
