import { combineReducers } from "@reduxjs/toolkit";
// Reducers
import auth from "@features/Auth/store/slice";
import searchResult from "@features/Search/store/slice";
import product from "@features/Product/store/slice";
import feedback from "@features/Feedback/store/slice";
import productList from "@features/ProductList/store/slice";
import accountManagement from "@features/AccountManagement/store/slice";
import catalog from "@features/Catalog/store/slice";
import matches from "@features/Matches/store/slice";
import matchesOld from "@features/Matches/store/sliceOld";
import emailBuyer from "@features/EmailBuyer/store/slice";
import banner from "@features/Banner/store/slice";
import transactions from "@features/Transactions/store/slice";

const rootReducer = combineReducers({
   auth,
   searchResult,
   product,
   feedback,
   productList,
   accountManagement,
   catalog,
   emailBuyer,
   matches,
   matchesOld,
   banner,
   transactions,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
