import React from "react";
// Icons
import HideEye from "@icons/HideEye.svg";
import ShowEye from "@icons/ShowEye.svg";
// Hooks
import { useToggle } from "@hooks/useToggle";
// Styles
import "./styles/main.scss";
// Components
import Input from "./subComponents/Input";
import ErrorMessage from "./subComponents/ErrorMessage";
import RightSideIcon from "./subComponents/RightSideIcon";
// Context
import ContextProvider from "./context";
// Types
import { FieldError } from "react-hook-form";

export type TTSizes = "default" | "fluid";

export type TTypes = "text" | "password";

export interface IProps {
   /**
    * To set the type of the input but when you set it to password
    * then the eye toggler will show and the isSearchEnter will not work.
    */
   type?: TTypes;
   /**
    * To set the input label.
    */
   label?: string;
   /**
    * To set the input placeholder.
    */
   placeholder?: string;
   /**
    * Name is required for the input because of react-hook-form and it also use the
    * name as id.
    */
   name?: string;
   /**
    * To set the size of the input.
    */
   size?: TTSizes;
   /**
    * To further customize the input you can pass a className
    */
   className?: string;
   /**
    * To set the input into disbaled state.
    */
   isDisabled?: boolean;
   /**
    * Error props should be from the react-hook-form so that
    * it will automatically catch the error and show the error message.
    */
   error?: FieldError;
   /**
    * To add right icon on the input.
    */
   rightIcon?: React.FC<React.SVGAttributes<SVGElement>>;
   /**
    * To add onClick listener to right icon
    */
   rightIconOnClick?: () => void;
   [x: string]: unknown;
}

const TextInputField: React.VFC<IProps> = React.forwardRef<HTMLInputElement, IProps>(
   (
      {
         type = "text",
         label = "INPUT",
         placeholder = "Enter your username",
         name = "",
         size = "default",
         className = "",
         isDisabled = false,
         rightIconOnClick = () => null,
         error = {},
         rightIcon,
         ...rest
      },
      ref
   ) => {
      const isPassword = type.toLowerCase() === "password";
      const [showPassword, handleShowPassword] = useToggle();

      return (
         <div
            className={`
			text-input
			text-input--size-${size}
			${className}
			`}
            data-testid="text-input-field"
         >
            <ContextProvider
               label={label}
               name={name}
               showPassword={showPassword}
               type={type}
               placeholder={placeholder}
               errorMessage={error.message}
               isDisabled={isDisabled}
            >
               <div
                  className={`
						text-input__wrapper
						text-input__wrapper--type-${type} 
						text-input__wrapper--${isDisabled ? "disabled" : ""}
						text-input__wrapper--${error.message ? "error" : ""}
						text-input__wrapper--${label ? "labeled" : ""}
					`}
               >
                  <Input {...rest} ref={ref} />
                  {!isPassword && rightIcon && (
                     <RightSideIcon
                        data-testid="right-side-icon"
                        wrapperClassName="text-input__right-icon-wrapper"
                        icon={rightIcon}
                        handleOnClick={() => rightIconOnClick()}
                     />
                  )}
                  {isPassword && (
                     <RightSideIcon
                        data-testid="password-toggler"
                        wrapperClassName="text-input__password-toggler-wrapper"
                        icon={showPassword ? HideEye : ShowEye}
                        handleOnClick={() => handleShowPassword()}
                     />
                  )}
               </div>
               <ErrorMessage />
            </ContextProvider>
         </div>
      );
   }
);

TextInputField.displayName = "TextInputField";
export default React.memo(TextInputField);
