import { createContext, ReactNode } from "react";

interface IProps {
   children: ReactNode;
   [x: string]: unknown;
}

type TContextValues = {
   isDisabled?: boolean;
   isSearchEnter?: boolean;
   isLoading?: boolean;
};

export const Context = createContext<TContextValues>({});

const ContextProvider = ({ children, ...rest }: IProps) => {
   return <Context.Provider value={{ ...rest }}>{children}</Context.Provider>;
};

export default ContextProvider;
