import React, { useState, useEffect } from "react";
// Styles
import "./styles/styles.scss";
// Icons
import InfoIcon from "@icons/InfoIcon.svg";
import CloseIcon from "@icons/CloseIcon.svg";
// Types
import { TToast } from "../../context";

const ToastItem = ({ toast }: { toast: TToast }) => {
   const [isActive, setIsActive] = useState(true);

   useEffect(() => {
      setTimeout(() => {
         setIsActive(false);
      }, 2990);
   }, []);

   const handleHideToast = () => {
      setIsActive(false);
   };

   return (
      <div
         className={`
            toast__item 
			toast__item--${toast?.state || "solid"}
			toast__item--${isActive ? "active" : "removed"}
			toast__item--${toast?.type as string}`}
         key={toast.id}
      >
         <InfoIcon className="toast__item__icon" />
         <p className="toast__item__message">{toast?.message}</p>
         <CloseIcon
            data-testid="toast-item-close"
            onClick={handleHideToast}
            className="toast__item__icon toast__item__icon--close"
         />
      </div>
   );
};

export default React.memo(ToastItem);
