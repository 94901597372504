import React, { useContext, useLayoutEffect } from "react";
// Components
import Drawer from "../../subComponents/Drawer";
import SearchInputForm from "../../subComponents/SearchInputForm";
import ProductList from "../../subComponents/ProductList";
import NavigationIcon from "../../subComponents/NavigationIcon";
import UserMenu from "../../subComponents/UserMenu";
import NavigationLinks from "../../subComponents/NavigationLinks";
import CurrencyList from "../../subComponents/CurrencyList";
// Icons
import LogoIcon from "@icons/LogoIcon.svg";
import CloseIcon from "@icons/CloseIcon.svg";
import BurgerIcon from "@icons/BurgerIcon.svg";
import CurrencyIcon from "@icons/Currency.svg";
import ListCheckListIcon from "@icons/ListCheckListIcon.svg";
// Context
import { Context } from "../../context";
// Styles
import "./styles/styles.scss";
// Hooks
import { useToggle } from "@hooks/useToggle";
// Router
import { useLocation } from "react-router-dom";

const Mobile = () => {
   const { activeMenu, handleToggleMenu, handleNavigateToHomePage, links } =
      useContext(Context);
   // Router methods
   const location = useLocation();
   // Others
   const [isSidebarOpen, handleToggleSidebar] = useToggle();

   useLayoutEffect(() => {
      /**
       * On mobile to auto hide the sidebar
       * we are going to check if the location is changing.
       */
      handleToggleSidebar(false);
      handleToggleMenu?.(null);
   }, [location]);

   return (
      <div
         className={`
			nav-bar
			nav-bar--${isSidebarOpen ? "open" : "close"}
			pt-3 pb-3`}
      >
         <div className="nav-bar__overlay" onClick={() => handleToggleSidebar()}></div>
         <div className="nav-bar__container">
            <div className="nav-bar__wrapper">
               <LogoIcon className="nav-bar__logo" onClick={handleNavigateToHomePage} />
               <div className="nav-bar__links-wrapper">
                  <Drawer className="nav-bar__icon-links-wrapper">
                     {/* Product List Wrapper */}
                     <React.Fragment>
                        <NavigationIcon
                           className="nav-bar__product-list-icon"
                           isActive={activeMenu === "PRODUCT_LIST"}
                           onClick={() => handleToggleMenu?.("PRODUCT_LIST")}
                           icon={ListCheckListIcon}
                           number={null}
                        />
                        {activeMenu === "PRODUCT_LIST" && <ProductList />}
                        <React.Fragment>
                           <NavigationIcon
                              className="nav-bar__currency-icon"
                              isActive={activeMenu === "CURRENCY"}
                              onClick={() => handleToggleMenu?.("CURRENCY")}
                              icon={CurrencyIcon}
                              number={null}
                           />
                           {activeMenu === "CURRENCY" && <CurrencyList />}
                        </React.Fragment>
                     </React.Fragment>
                     {/* Notification List Wrapper --pending */}
                     {/* <React.Fragment>
								<NavigationIcon icon={BellIcon} number={3} />
							</React.Fragment> */}
                  </Drawer>
               </div>
               <BurgerIcon
                  onClick={() => handleToggleSidebar()}
                  className="nav-bar__burger-menu"
               />
               <div className="nav-bar__side-bar">
                  <CloseIcon
                     className="nav-bar__close-icon"
                     onClick={() => handleToggleSidebar()}
                  />
                  <div className="nav-bar__search-input-wrapper">
                     <SearchInputForm />
                  </div>
                  <NavigationLinks links={links} />
                  <UserMenu />
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(Mobile);
