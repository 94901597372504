export const searchResultRequestType = {
   GET_SEARCH_RESULT: "GET_SEARCH_RESULT",
   GET_SEARCH_WAREHOUSES: "GET_SEARCH_WAREHOUSES",
   GET_FILTER_BRANCH: "GET_FILTER_BRANCH",
   GET_FILTER_PRICE: "GET_FILTER_PRICE",
   GET_FILTER_SUPPLIERS: "GET_FILTER_SUPPLIERS",
   UPDATE_CURRENCY: "UPDATE_CURRENCY",
} as const;

export type TReqType =
   (typeof searchResultRequestType)[keyof typeof searchResultRequestType];

export const stateType = {
   search: "search",
   branch: "branch",
   suppliers: "suppliers",
   warehouses: "warehouses",
   price: "price",
   currency: "currency",
} as const;

export type TStateType = (typeof stateType)[keyof typeof stateType];
