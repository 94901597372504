import { createSlice } from "@reduxjs/toolkit";
// Types
import { IStates, TPayload } from "./types";
// Utils
import { status } from "@utils/constants";
import { stateType } from "@features/Transactions/utils/constants";

export const transactions = createSlice({
   name: "transactions",
   initialState: {
      transactions: {
         items: {
            transactions: [],
            total: 0,
            page: 0,
            perPage: 0,
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
      branches: {
         items: [],
         status: status.IDLE,
         error: {
            data: {},
         },
      },
      ranges: {
         items: {
            amount: {
               min: 0,
               max: 0,
            },
            totalPrice: {
               min: 0,
               max: 0,
            },
            pricePerUnit: {
               min: 0,
               max: 0,
            },
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
      download: {
         items: {
            url: "",
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
   } as IStates,
   reducers: {
      transactionsRequest: (state, { payload }: TPayload) => {
         // Put back the status of the rest state to idle
         Object.values(stateType).forEach((key) => {
            /**
             * If state status is not pending then we will set it to idle
             * so that we will not reset a pending request.
             */
            if (key !== payload.stateType && state[key].status !== status.PENDING) {
               state[key].status = status.IDLE;
            } else {
               // Then update the status and error for the passed stateType
               state[key].status = status.PENDING;
               state[key].error = {
                  data: {},
               };
            }
         });
      },
      transactionsResolved: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items;
         state[payload.stateType].status = status.RESOLVED;
         state[payload.stateType].error = {
            data: {},
         };
      },
      transactionsRejected: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items ?? state[payload.stateType].items;
         state[payload.stateType].error = payload.error;
         state[payload.stateType].status = status.REJECTED;
      },
   },
});

export const { transactionsRequest, transactionsResolved, transactionsRejected } =
   transactions.actions;

export default transactions.reducer;
