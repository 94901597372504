import React, { lazy, Suspense } from "react";
// Components
import Box from "@components/Box";
import PrimaryButton from "@components/PrimaryButton";
// Styles
import "./styles/main.scss";
// Icons
import ExpandIcon from "@icons/ExpandIcon.svg";
import TrashIcon from "@icons/TrashIcon.svg";
// Router
import { useNavigate } from "react-router-dom";
// Layouts
import LoadingOverlay from "@layouts/LoadingOverlay";
import ErrorBoundary from "@layouts/ErrorBoundary";
// Redux
import { useDispatch } from "react-redux";
import { productListRequest } from "@features/ProductList/store/slice";
// Utils
import {
   productListRequestType as reqType,
   productListStatusCode as statusCode,
} from "@features/ProductList/utils/constants";
// Lazy components
const List = lazy(() => import("@features/ProductList/components/List"));

const ProductList: React.FC = () => {
   // Redux methods
   const dispatch = useDispatch();
   // Router methods
   const navigate = useNavigate();

   const handleNavigateToProductList = () => {
      navigate("/product-in-list");
   };

   const handleDeleteList = () => {
      dispatch(
         productListRequest({
            type: reqType.DELETE_LIST_PRODUCTS,
            code: statusCode.DELETE_LIST_PRODUCT_PENDING,
         })
      );
   };

   return (
      <Box className="floating-product-list" data-testid="floating-product-list">
         <Box.Header className="floating-product-list__header">
            <p className="h5">Products in your list</p>
            <div className="floating-product-list__tools">
               <ExpandIcon
                  data-testid="floating-product-list-expand"
                  onClick={handleNavigateToProductList}
                  className="floating-product-list__tool-icon floating-product-list__tool-icon--expand"
               />
               <TrashIcon
                  onClick={handleDeleteList}
                  className="floating-product-list__tool-icon floating-product-list__tool-icon--trash"
               />
            </div>
         </Box.Header>
         <Box.Content className="floating-product-list__content">
            <Suspense fallback={<LoadingOverlay />}>
               <div
                  className="floating-product-list__content__list"
                  id="floating-product-list"
               >
                  <ErrorBoundary fallback={<h2>Something went wrong!</h2>}>
                     <List
                        itemProps={{
                           withCheckBox: false,
                           withContactButton: false,
                           withDeleteButton: true,
                           withOpenButton: true,
                        }}
                        scrollTarget="floating-product-list"
                     />
                  </ErrorBoundary>
               </div>
            </Suspense>
         </Box.Content>
         <Box.Footer className="floating-product-list__footer">
            <div className="floating-product-list__button">
               <PrimaryButton handleOnClick={handleNavigateToProductList}>
                  <PrimaryButton.Content>Use List</PrimaryButton.Content>
               </PrimaryButton>
            </div>
         </Box.Footer>
      </Box>
   );
};

export default ProductList;
