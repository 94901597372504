import { axios } from "@redux/store";
// Utils
import { feedBackStatusCode as statusCode } from "../../../utils/constants";
// Types
import { TFeedbackShape } from "../request/types";
import { AxiosResponse } from "axios";
import { TFeedbackStatusCodeType } from "../../../utils/constants";
// Saga
import { CallEffect, call } from "redux-saga/effects";

export function* handlePostFeedback({ shape }: { shape: TFeedbackShape }): Generator<
   CallEffect<AxiosResponse>,
   {
      code: TFeedbackStatusCodeType;
      error?: unknown;
   },
   never
> {
   try {
      const result: AxiosResponse = yield call(() => {
         return axios({
            url: "/feedback",
            method: "POST",
            payload: shape,
         });
      });
      /*===============================================
		# As the api is not returning a 400 status code
		# when rejected we gonna give it a condition
		# instead so that we could still simulate the 
		# rejection using throw.
		===============================================*/
      if (result?.data?.errorMessage) {
         throw result?.data?.errorMessage;
      } else {
         return {
            code: statusCode.FEEDBACK_SUCCESS,
         };
      }
   } catch (err: unknown) {
      throw {
         code: statusCode.FEEDBACK_FAILED,
         error: {
            message: err instanceof Error ? err?.message || "Error" : "",
         },
      };
   }
}
