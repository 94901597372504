import { useMemo } from "react";
// Typeform
import { Sidetab, Popover } from "@typeform/embed-react";
// Colors
import { primaryColor } from "@styles/colors/_primary.module.scss";
// Hooks
import { useMediaQuery } from "@hooks/useMediaQuery";
// Screens
import screen from "@styles/screens/_sizes.module.scss";
// Router
import { useLocation } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Types
import { RootState } from "@redux/rootReducer";
// Styles
import "./styles/main.scss";

interface IProps {
   desktopExcludedLocation?: string[];
   mobileExcludedLocation?: string[];
}

const TypeFormFeedBack = ({
   mobileExcludedLocation = [],
   desktopExcludedLocation = [],
}: IProps) => {
   const isMobile = useMediaQuery(`(max-width: ${screen.mobile})`);
   // Redux methods
   const { user } = useSelector((state: RootState) => state.auth);
   // Router methods
   const location = useLocation();

   const hiddenFields = useMemo(() => {
      return {
         email: user?.email || "null",
         first_name: user?.firstName || "null",
         last_name: user?.lastName || "null",
         branch: user?.branchName || "null",
      };
   }, [JSON.stringify(user)]);

   const shouldShow = useMemo<boolean>(() => {
      return !(isMobile ? mobileExcludedLocation : desktopExcludedLocation)?.includes(
         location.pathname
      );
   }, [
      JSON.stringify(mobileExcludedLocation),
      JSON.stringify(desktopExcludedLocation),
      isMobile,
   ]);

   return !isMobile ? (
      shouldShow ? (
         <Sidetab
            id="xvJFpuzw"
            buttonText="Feedback & Bug Report"
            buttonColor={primaryColor}
            hidden={hiddenFields}
         />
      ) : null
   ) : shouldShow ? (
      <Popover id="xvJFpuzw" hidden={hiddenFields} buttonColor={primaryColor} />
   ) : null;
};

export default TypeFormFeedBack;
