import { useEffect, useState } from "react";

export const useMediaQuery = (mediaQuery: string) => {
   const [isMatched, setIsMatched] = useState<boolean>(() => {
      return window?.matchMedia(mediaQuery)?.matches;
   });

   const handleUpdateIsMatched = () => {
      setIsMatched(window?.matchMedia(mediaQuery)?.matches);
   };

   useEffect(() => {
      window.addEventListener("resize", handleUpdateIsMatched);

      return () => {
         window.removeEventListener("resize", handleUpdateIsMatched);
      };
   }, [isMatched, mediaQuery]);

   return isMatched;
};
