import {
   CallEffect,
   ForkEffect,
   PutEffect,
   SagaReturnType,
   call,
   put,
   takeEvery,
   takeLatest,
} from "redux-saga/effects";
// Utils
import { matchesRequestType as reqType, stateType } from "../../../utils/constants";
// Types
import { TPayload } from "./types";
import { AnyAction } from "@reduxjs/toolkit";
// Actions
import { matchesRejected, matchesRequest, matchesResolved } from "../../slice";
// Handlers
import {
   handleGetBranches,
   handleGetMatches,
   handleGetRanges,
   handleDownloadMatches,
} from "../handlers/index";

function* getMatches({
   filters,
   order,
   currency,
   branchId,
   pagination,
}: AnyAction & TPayload): Generator<CallEffect | PutEffect, void, never> {
   try {
      // Request for the branch first
      yield put(
         matchesRequest({
            type: reqType.GET_MATCHES_BRANCHES,
            stateType: stateType.branches,
         })
      );
      // Then request for the ranges
      yield put(
         matchesRequest({
            type: reqType.GET_MATCHES_RANGES,
            stateType: stateType.ranges,
            branchId,
            currency,
         })
      );
      // Finally request for the matches
      const res: SagaReturnType<typeof handleGetMatches> = yield call(handleGetMatches, {
         filters,
         order,
         currency,
         pagination,
         branchId,
      });
      yield put(matchesResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetMatches>;
      yield put(matchesRejected(error));
   }
}

function* downloadMatches({
   filters,
   order,
   currency,
   branchId,
}: AnyAction & Omit<TPayload, "pagination">): Generator<
   CallEffect | PutEffect,
   void,
   never
> {
   try {
      // Request for the branch first
      yield put(
         matchesRequest({
            type: reqType.GET_MATCHES_BRANCHES,
            stateType: stateType.branches,
         })
      );
      // Then request for the ranges
      yield put(
         matchesRequest({
            type: reqType.GET_MATCHES_RANGES,
            stateType: stateType.ranges,
            branchId,
            currency,
         })
      );
      // Finally request for the matches
      const res: SagaReturnType<typeof handleDownloadMatches> = yield call(
         handleDownloadMatches,
         {
            filters,
            order,
            currency,
            branchId,
         }
      );
      yield put(matchesResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetMatches>;
      yield put(matchesRejected(error));
   }
}

function* getBranches({}: AnyAction & TPayload): Generator<
   CallEffect | PutEffect,
   void,
   never
> {
   try {
      const res: SagaReturnType<typeof handleGetBranches> = yield call(handleGetBranches);
      yield put(matchesResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetBranches>;
      yield put(matchesRejected(error));
   }
}

function* getRanges({
   currency,
   branchId,
}: AnyAction & TPayload): Generator<CallEffect | PutEffect, void, never> {
   try {
      const res: SagaReturnType<typeof handleGetRanges> = yield call(handleGetRanges, {
         currency,
         branchId,
      });
      yield put(matchesResolved(res));
   } catch (err) {
      const error = err as SagaReturnType<typeof handleGetRanges>;
      yield put(matchesRejected(error));
   }
}

export default function* (): Generator<PutEffect | ForkEffect, void, never> {
   // Request's
   yield takeEvery("matches/matchesRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(reqType.GET_MATCHES_RANGES, getRanges);
   yield takeLatest(reqType.GET_MATCHES_BRANCHES, getBranches);
   yield takeLatest(reqType.GET_MATCHES, getMatches);
   yield takeLatest(reqType.DOWNLOAD_MATCHES, downloadMatches);
}
