export const catalogRequestType = {
   GET_CATALOG_CATEGORY: "GET_CATALOG_CATEGORY",
   EXPORT_CATALOG_PRODUCTS: "EXPORT_CATALOG_PRODUCTS",
   GET_CATALOG_SUPPLIERS: "GET_CATALOG_SUPPLIERS",
   GET_CATALOG_WAREHOUSES: "GET_CATALOG_WAREHOUSES",
   GET_CATALOG_BRANCHES: "GET_CATALOG_BRANCHES",
   GET_CATALOG_PRODUCTS: "GET_CATALOG_PRODUCTS",
   GET_CATALOG_ACCESSIBLE_BRANCHES: "GET_CATALOG_ACCESSIBLE_BRANCHES",
} as const;

export type TReqType = (typeof catalogRequestType)[keyof typeof catalogRequestType];

export const stateType = {
   branch: "branches" as const,
   accessibleBranches: "accessibleBranches" as const,
   suppliers: "suppliers" as const,
   warehouses: "warehouses" as const,
   category: "category" as const,
   products: "products" as const,
   exportProducts: "exportProducts" as const,
};

export type TStateType = (typeof stateType)[keyof typeof stateType];
