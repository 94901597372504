import { createSlice } from "@reduxjs/toolkit";
// Types
import { IStates, TPayload } from "./types";
import { stateType } from "@features/Catalog/utils/constants";
// Utils
import { status } from "@utils/constants";

const catalog = createSlice({
   name: "catalog",
   initialState: {
      exportProducts: {
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      branches: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      accessibleBranches: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      suppliers: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      warehouses: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      category: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      products: {
         items: {
            parentCategory: {
               category: "",
               id: "",
            },
            products: [],
            perPage: 0,
            page: 1,
            total: 0,
         },
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      sortBy: {
         items: undefined,
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
   } as IStates,
   reducers: {
      catalogRequest: (state, { payload }: TPayload) => {
         // Put back the status of the rest state to idle
         Object.values(stateType).forEach((key) => {
            /**
             * If state status is not pending then we will set it to idle
             * so that we will not reset a pending request.
             */
            if (key !== payload.stateType && state[key].status !== status.PENDING) {
               state[key].status = status.IDLE;
            } else {
               // Then update the status and error for the passed stateType
               state[key].status = status.PENDING;
               state[key].error = {
                  data: {},
               };
            }
         });
      },
      catalogResolved: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items;
         state[payload.stateType].error = {
            data: {},
         };
         state[payload.stateType].status = status.RESOLVED;
      },
      catalogRejected: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items ?? state[payload.stateType].items;
         state[payload.stateType].error = payload.error;
         state[payload.stateType].status = status.REJECTED;
      },
   },
});

export const { catalogRequest, catalogResolved, catalogRejected } = catalog.actions;
export default catalog.reducer;
