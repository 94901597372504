import { lazy, Fragment, Suspense, useEffect } from "react";
// Redux
import { RootState } from "@redux/rootReducer";
import { authRequest } from "@features/Auth/store/slice";
import { useDispatch, useSelector } from "react-redux";
// Router
import { Outlet } from "react-router-dom";
// Utils
import { authStatusCode, authRequestType } from "@features/Auth/utils/constants";
// Layouts
import LoadingOverlay from "../../layouts/LoadingOverlay";
import TypeFormFeedback from "@layouts/TypeFormFeedback";
import NavigationBar from "@layouts/NavigationBar";
// Page
const SignIn = lazy(() => import("../../pages/SignIn"));

const ProtectedRoute: React.FC = () => {
   // Redux
   const dispatch = useDispatch();
   const { code, user } = useSelector((state: RootState) => state.auth);

   const handleSignOut = () => {
      dispatch(
         authRequest({
            type: authRequestType.SIGN_OUT,
         })
      );
   };

   useEffect(() => {
      if (
         code === authStatusCode.SIGN_IN_SUCCESS ||
         code === authStatusCode.VALIDATE_TOTP_SUCCESS ||
         code === authStatusCode.VALIDATE_MFA_SUCCESS ||
         (typeof code === "string" && !code)
      ) {
         dispatch(
            authRequest({
               type: authRequestType.GET_SESSION,
               code: authStatusCode.GET_SESSION_PENDING,
               user: {},
            })
         );
      } else if (code === authStatusCode.GET_SESSION_FAILURE) {
         handleSignOut();
      }
   }, [code]);

   if (
      (code === authStatusCode.GET_SESSION_PENDING && !user?.isSignedIn) ||
      (typeof code === "string" && !code)
   ) {
      return <LoadingOverlay />;
   } else if (user?.isSignedIn) {
      return (
         <Fragment>
            <NavigationBar />
            <Outlet />
            <TypeFormFeedback mobileExcludedLocation={["/product-in-list"]} />
         </Fragment>
      );
   }

   return (
      <Suspense fallback={<LoadingOverlay />}>
         <SignIn />
      </Suspense>
   );
};

export default ProtectedRoute;
