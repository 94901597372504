type TTagManagerEvent = {
   event: string;
   [key: string]: unknown;
};

type WindowWithDataLayer = Window & {
   dataLayer: TTagManagerEvent[];
};

declare const window: WindowWithDataLayer;

export const GoogleTagEvent = {
   ClarityInit: `clarity-init-${import.meta.env.VITE_STAGE}`,
   ClarityIdentify: "clarity-identify-user",
} as const;

export function pushCustomGTMEvent(data: TTagManagerEvent) {
   if (!window.dataLayer) {
      window.dataLayer = [];
   }
   window.dataLayer.push(data);
}
