import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
import { TStateType, stateType } from "@features/Banner/utils/constants";
// Types
import { IStates, TPayload } from "./types";

const banner = createSlice({
   name: "banner",
   initialState: {
      banner: {
         item: {
            id: "",
            type: null,
            content: "",
            createdAt: "",
         },
         status: status.IDLE,
         error: {
            data: {},
         },
      },
   } as IStates,
   reducers: {
      bannerRequest: (state, { payload }: TPayload) => {
         // Put back the status of the rest state to idle
         Object.values(stateType).forEach((key) => {
            /**
             * If state status is not pending then we will set it to idle
             * so that we will not reset a pending request.
             */
            if (
               key !== payload.stateType &&
               state[key as TStateType].status !== status.PENDING
            ) {
               state[key as TStateType].status = status.IDLE;
            } else {
               // Then update the status and error for the passed stateType
               state[key].status = status.PENDING;
               state[key].error = {
                  data: {},
               };
            }
         });
      },
      bannerResolved: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items;
         state[payload.stateType].status = status.RESOLVED;
         state[payload.stateType].error = {
            data: {},
         };
      },
      bannerRejected: (state, { payload }: TPayload) => {
         state[payload.stateType].items = payload.items ?? state[payload.stateType].items;
         state[payload.stateType].error = payload.error;
         state[payload.stateType].status = status.REJECTED;
      },
   },
});

export const { bannerRequest, bannerResolved, bannerRejected } = banner.actions;
export default banner.reducer;
