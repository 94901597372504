import { useEffect, useRef, useState } from "react";

/**
 * This hook detects when user clicks outside the component
 * by adding the "targetRef" to the parent element that you want to
 * watch then the "isClickedOutside" boolean will tell you if user
 * clicks outside the component or not.
 */
export const useIsClickedOutsideComponent = <T extends HTMLElement>() => {
   const targetRef = useRef<T | null>(null);
   const [isClickedOutside, setIsClicked] = useState<boolean>(false);

   const isClickedOutsideFunction = (e: MouseEvent) => {
      setIsClicked(!targetRef.current?.contains(e.target as T));
   };

   useEffect(() => {
      document.addEventListener("click", isClickedOutsideFunction, true);
      return () => {
         document.removeEventListener("click", isClickedOutsideFunction);
      };
   }, []);

   return { targetRef, isClickedOutside };
};
