import { AxiosError } from "axios";
// Saga
import { CallEffect, call } from "redux-saga/effects";
// Store
import { axios } from "@redux/store";
// Types
import { TCategories, TError, TMatch, TProduct, TStock } from "../../slice/types";
import { TProductPayload } from "../request/types";
import {
   TGetCategoriesResponse,
   TGetProductMatchResponse,
   TGetProductResponse,
   TGetStockResponse,
} from "./types";
import { TStateType, stateType } from "@features/Product/utils/constants";
// UUID
import uuid from "react-uuid";
// Old
import { AxiosResponse } from "axios";
import { TMatchShape } from "./types";
import { TMatchOld as TMatchOld } from "../../sliceOld/types";
import { currencyFormat } from "@utils/currencyFormat";

export function* handleGetProduct({ pid }: TProductPayload): Generator<
   CallEffect,
   {
      items?: TProduct;
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const result: TGetProductResponse = yield call(() => {
         return axios({
            url: `/products/${pid as string}`,
            method: "GET",
         });
      });

      return {
         stateType: stateType.product,
         items: result?.data,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.product,
      };
   }
}

export function* handleGetCategories({ pid }: TProductPayload): Generator<
   CallEffect,
   {
      items?: TCategories;
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const result: TGetCategoriesResponse = yield call(() => {
         return axios({
            url: `/products/categories/${pid as string}`,
            method: "GET",
         });
      });

      return {
         items: result.data,
         stateType: stateType.categories,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.categories,
      };
   }
}

export function* handleGetMatch({ pid, currency }: TProductPayload): Generator<
   CallEffect,
   {
      items?: TMatch[];
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const result: TGetProductMatchResponse = yield call(() => {
         return axios({
            url: `/products/match/${pid as string}/${currency?.currency || "USD"}`,
            method: "GET",
         });
      });

      return {
         items: result?.data?.items,
         stateType: stateType.match,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.match,
      };
   }
}

export function* handleGetMatchOld({ pid, currency }: TProductPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      items?: TMatchOld[];
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const result: AxiosResponse<{
         items: TMatchShape[];
      }> = yield call(() => {
         return axios({
            url: `/products/match/${pid as string}?currency=${
               currency?.currency || "USD"
            }`,
            method: "GET",
         });
      });

      // Update table shape
      const updatedShape = result?.data?.items.reduce(
         (a: TMatchOld[], c: TMatchShape) => {
            const shape: TMatchOld = {
               id: c?.id,
               branch: c?.branch?.name,
               branchPartNumber: c?.partNumber,
               supplier: c?.buyingOptions[0].supplier?.name,
               supplierPartNumber: c?.buyingOptions[0].supplier?.partNumber,
               price: currencyFormat(
                  c?.buyingOptions[0].sell?.price as number,
                  currency?.locale as string,
                  currency?.currency as string
               ),
               isInList: c?.inList,
               pid: c?.id,
            };
            // If buyingOptions array only have 1 object inside we will push it directly to accumulator
            if (c.buyingOptions.length <= 1) {
               a.push(shape);
            } else {
               /**
                * Else we will iterate the buying options then
                * push new instance of object with each sell and supplier from buying options
                */
               c?.buyingOptions?.forEach((match) => {
                  a.push({
                     ...shape,
                     id: `${c.id}${JSON.stringify(match)}`,
                     pid: match?.productId,
                     branch: match?.branch?.name,
                     branchPartNumber: match?.branch?.partNumber,
                     supplier: match?.supplier?.name,
                     supplierPartNumber: match?.supplier?.partNumber,
                     price: currencyFormat(
                        match.sell?.price as number,
                        currency?.locale as string,
                        currency?.currency as string
                     ),
                  });
               });
            }
            return a;
         },
         []
      );

      return {
         items: updatedShape,
         stateType: stateType.matchOld,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.match,
      };
   }
}

export function* handleGetStock({ pid }: TProductPayload): Generator<
   CallEffect,
   {
      items?: TStock[];
      error?: TError;
      stateType: TStateType;
   },
   never
> {
   try {
      const result: TGetStockResponse = yield call(() => {
         return axios({
            url: `/products/stock/${pid as string}`,
            method: "GET",
         });
      });

      // We gonna update the shape to include id
      const updatedShape = result?.data?.items?.map((item) => {
         return {
            id: `${JSON.stringify(item) + uuid()}`,
            address: item.address,
            replenishment: item.replenishment,
            quantity: item.quantity,
         };
      });

      return {
         items: updatedShape,
         stateType: stateType.stock,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         stateType: stateType.match,
      };
   }
}
