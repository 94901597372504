import { Component, ErrorInfo, ReactNode, memo } from "react";

interface Props {
   children?: ReactNode;
   fallback?: ReactNode;
}

interface State {
   hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
   public state: State = {
      hasError: false,
   };

   // eslint-disable-next-line
   public static getDerivedStateFromError(_: Error): State {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
   }

   public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // eslint-disable-next-line
      console.error("Uncaught error:", error, errorInfo);
   }

   public render() {
      if (this.state.hasError) {
         return this.props.fallback;
      }

      return this.props.children;
   }
}

export default memo(ErrorBoundary);
