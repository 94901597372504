export const E2E_BASE_URL = "http://127.0.0.1:5173";

export const status = {
   IDLE: "IDLE",
   PENDING: "PENDING",
   RESOLVED: "RESOLVED",
   REJECTED: "REJECTED",
   UPDATING: "UPDATING",
} as const;

export type Status = (typeof status)[keyof typeof status];

export const networkConditions = {
   "Slow 3G": {
      download: ((500 * 1000) / 8) * 0.8,
      upload: ((500 * 1000) / 8) * 0.8,
      latency: 400 * 5,
   },
   "Fast 3G": {
      download: ((1.6 * 1000 * 1000) / 8) * 0.9,
      upload: ((750 * 1000) / 8) * 0.9,
      latency: 150 * 3.75,
   },
};
