import React, { ReactNode } from "react";
// Styles
import "@styles/_global.scss";
import "./styles/main.scss";
// Components
import Content from "./subComponents/Content";
import RightSideIcon from "./subComponents/RightSideIcon";
// Icons
import RightArrowIcon from "@icons/RightArrowIcon.svg";
// Context
import ContextProvider from "./context";

type TSizes = "small" | "large" | "x-large" | "fluid";

export interface IProps {
   /**
    * For modifying the size of the button.
    */
   size?: TSizes;
   /**
    * To set the button into disabled state.
    */
   isDisabled?: boolean;
   /**
    * If you want to add the right arrow icon.
    */
   isSearchEnter?: boolean;
   /**
    * To set the button into loading state.
    */
   isLoading?: boolean;
   /**
    * If you want to add an onClick event.
    */
   handleOnClick?(): void;
   /**
    * - <PrimaryButton.Content> -> Wrap your content with this.
    */
   children?: ReactNode;
   /**
    * To further customize the button you can pass a className.
    */
   className?: string;
}

const PrimaryButton = ({
   size = "small",
   isDisabled = false,
   isSearchEnter = false,
   isLoading = false,
   handleOnClick = () => null,
   children,
   className = "",
}: IProps) => {
   const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!isDisabled && !isLoading) {
         handleOnClick();
      }
   };

   return (
      <div
         onClick={handleClick}
         className={`
         primary-button
			primary-button--${isDisabled ? "disabled" : ""}
			primary-button--${isLoading ? "loading" : ""}
			primary-button--${size === "fluid" ? "fluid" : ""}
			${className}
			`}
         data-testid="primary-button"
      >
         <a
            href="#/"
            className={`
				primary-button__anchor
				primary-button__anchor--size-${size}
				primary-button__anchor--${isSearchEnter ? "search" : ""}
				p-16-reg
				`}
         >
            <ContextProvider
               isDisabled={isDisabled}
               isSearchEnter={isSearchEnter}
               isLoading={isLoading}
            >
               {children}
               {isSearchEnter && (
                  <RightSideIcon data-testid="right-side-icon" icon={RightArrowIcon} />
               )}
            </ContextProvider>
         </a>
      </div>
   );
};

PrimaryButton.Content = Content;

export default PrimaryButton;
