import { axios, store } from "@redux/store";
// Utils
import {
   productListStatusCode as statusCode,
   productListCacheKey as cacheKey,
} from "../../../utils/constants";
import {
   productRequestType,
   stateType as productStateType,
} from "@features/Product/utils/constants";
import { status } from "@utils/constants";
// Types
import { TError, TProduct } from "../../slice/types";
import { TProductListPayload } from "../request/types";
import { AxiosError } from "axios";
// Saga
import { CallEffect, PutEffect, call, put } from "redux-saga/effects";
// Types
import { RootState } from "@redux/rootReducer";
import { TGetProductListResponse } from "./types";
import { TStatusCode } from "../../../utils/constants";
// Actions
import { productRequest } from "@features/Product/store/slice";

export function* handleGetProductList({ page }: TProductListPayload): Generator<
   CallEffect,
   {
      error?: TError;
      code: TStatusCode;
      products?: TProduct[];
      total?: number;
      perPage?: number;
      page?: number;
   },
   never
> {
   try {
      const result: TGetProductListResponse = yield call(() => {
         return axios({
            url: `/lists/products?page=${page}&perPage=5`,
            method: "GET",
            cacheKey: `${cacheKey.GET_PRODUCT_LIST}-${page}`,
            shouldExpire: false,
         });
      });
      // Get the productList state
      const state = JSON.parse(
         JSON.stringify(store.getState().productList)
      ) as RootState["productList"];

      // Update the products and format it so that it is exactly like the TProduct type
      const updatedProducts = state.products
         ?.concat(state?.products?.length >= result.data.total ? [] : result?.data?.items)
         // Here we are filtering out duplicate products
         ?.filter((e, i, s) => s.findIndex((f) => f.listItemId === e.listItemId) === i);

      if (updatedProducts.length) {
         return {
            code: statusCode.GET_LIST_PRODUCT_SUCCESS,
            products: updatedProducts,
            total: result.data?.total,
            perPage: result.data?.perPage,
            page: result.data?.page,
         };
      } else {
         return {
            code: statusCode.GET_LIST_PRODUCT_SUCCESS,
            error: {
               message: "Your list is empty",
               data: {},
            },
         };
      }
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      const errorData = error.response?.data?.error;
      throw {
         code: statusCode.GET_LIST_PRODUCT_FAILURE,
         error: {
            ...errorData,
            message:
               errorData?.message === "Active list not found"
                  ? "Your list is empty"
                  : // If no error message then we will return the error type
                    errorData?.message || errorData?.type,
         },
      };
   }
}

export function* handlePushProductIntoList({ pid }: TProductListPayload): Generator<
   CallEffect | PutEffect,
   {
      code: TStatusCode;
      error?: TError;
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: `/lists/products/${pid as string}`,
            method: "POST",
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });

      // Then we will refetch the pushed product and set the status to UPDATING
      yield put(
         productRequest({
            stateType: productStateType.product,
            type: productRequestType.GET_PRODUCT,
            status: status.UPDATING,
            pid,
         })
      );

      return {
         code: statusCode.PUSH_PRODUCT_INTO_LIST_SUCCESS,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;

      throw {
         error: error.response?.data.error,
         code: statusCode.PUSH_PRODUCT_INTO_LIST_FAILURE,
      };
   }
}

export function* handleRemoveProductFromList({ pid }: TProductListPayload): Generator<
   CallEffect | PutEffect,
   {
      code: TStatusCode;
      products?: TProduct[];
      total?: number;
      error?: TError;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: `/lists/products/${pid as string}`,
            method: "DELETE",
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });

      // Get the productList state
      const state = JSON.parse(
         JSON.stringify(store.getState().productList)
      ) as RootState["productList"];
      // Then we will remove the deleted product from state
      const updatedProducts = state?.products?.filter(
         (product) => product.id !== (pid as string)
      );

      if (state.total - 1) {
         return {
            // Then return the updated products
            products: updatedProducts,
            // We will decrease the total
            total: state.total - 1,
            code: statusCode.REMOVE_PRODUCT_FROM_LIST_SUCCESS,
         };
      } else {
         return {
            code: statusCode.REMOVE_PRODUCT_FROM_LIST_SUCCESS,
            products: [],
            error: {
               message: "Your list is empty",
               data: {},
            },
         };
      }
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;

      throw {
         error: error.response?.data.error,
         code: statusCode.REMOVE_PRODUCT_FROM_LIST_FAILURE,
      };
   }
}

export function* handleDeleteList(): Generator<
   CallEffect,
   {
      code: TStatusCode;
      products?: TProduct[];
      error?: TError;
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: "/lists",
            method: "DELETE",
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });

      return {
         code: statusCode.DELETE_LIST_PRODUCT_SUCCESS,
         products: [],
         total: 0,
         error: {
            message: "Your list is empty",
            data: {},
         },
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         code: statusCode.DELETE_LIST_PRODUCT_FAILURE,
      };
   }
}

export function* handleSaveList({ listName }: TProductListPayload): Generator<
   CallEffect,
   {
      code: TStatusCode;
      products?: TProduct[];
      error: TError;
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: "/lists/disable",
            method: "PATCH",
            payload: {
               saveListAs: listName,
            },
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });

      return {
         code: statusCode.SAVE_PRODUCT_LIST_SUCCESS,
         products: [],
         total: 0,
         error: {
            message: "Your list is empty",
            data: {},
         },
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         code: statusCode.SAVE_PRODUCT_LIST_FAILURE,
         error: error.response?.data.error,
      };
   }
}
