import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IStates } from "./types";

const feedback = createSlice({
   name: "feedback",
   initialState: {
      status: status.IDLE,
      code: "",
      error: {},
   } as IStates,
   reducers: {
      feedbackResetResponse: (state) => {
         state.code = "";
      },
      feedbackRequest: (state, { payload }) => {
         state.status = status.PENDING;
         state.error = {};
         state.code = payload.code;
      },
      feedbackResolved: (state, { payload }) => {
         state.code = payload.code;
         state.status = status.RESOLVED;
         state.error = {};
      },
      feedbackRejected: (state, { payload }) => {
         state.code = payload.code;
         state.status = status.REJECTED;
         state.error = payload.error;
      },
   },
});

export const {
   feedbackRequest,
   feedbackResetResponse,
   feedbackResolved,
   feedbackRejected,
} = feedback.actions;
export default feedback.reducer;
