import { createContext, ReactNode } from "react";
// Types
import { TTypes } from ".";

interface IProps {
   children: ReactNode;
   [x: string]: unknown;
}

type TContextValues = {
   label?: string;
   name?: string;
   showPassword?: boolean;
   type?: TTypes;
   placeholder?: string;
   errorMessage?: string;
   isDisabled?: boolean;
};

export const Context = createContext<TContextValues>({});

const ContextProvider = ({ children, ...rest }: IProps) => {
   return <Context.Provider value={{ ...rest }}>{children}</Context.Provider>;
};

export default ContextProvider;
