import React from "react";
// Components
import JumpingDotsLoader from "./subComponents/JumpingDotsLoader";
// Styles
import "./styles/main.scss";

const LoadingOverlay: React.FC = () => {
   return (
      <div className="loading-overlay">
         <div className="loading-overlay__wrapper">
            <JumpingDotsLoader />
         </div>
      </div>
   );
};

export default React.memo(LoadingOverlay);
