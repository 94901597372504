import React, { ReactNode } from "react";
// Styles
import "./styles/styles.scss";

interface IProps {
   children: ReactNode;
   className?: string;
   [x: string]: unknown;
}

const Footer = ({ children, className = "", ...rest }: IProps) => {
   return (
      <div className={`box__footer ${className}`} {...rest}>
         {children}
      </div>
   );
};

export default React.memo(Footer);
