import { useEffect, createContext, useContext, useRef, ReactNode } from "react";
// Router
import { useLocation } from "react-router-dom";

interface IProviderProps {
   children: ReactNode;
}

type TContextValues = {
   lastLocation?: string;
};

const Context = createContext<TContextValues>({});

/*======================================================
# To make the hook work we need to wrap all of the pages 
# with this provider and the provider must be inside the 
# <Router/>
======================================================*/
export const LastLocationProvider = ({ children }: IProviderProps) => {
   const location = useLocation();
   const lastLocation = useRef<string>("");

   useEffect(() => {
      return () => {
         lastLocation.current = window.location.href;
      };
   }, [location]);

   return (
      <Context.Provider value={{ lastLocation: lastLocation.current }}>
         {children}
      </Context.Provider>
   );
};

// The hook will return the last location you've been with inside the app.
export const useLastLocation = (): string => {
   const { lastLocation } = useContext(Context);

   return lastLocation || "";
};
