import React, { useEffect } from "react";
// Components
import Modal from "@components/Modal";
import PrimaryButton from "@components/PrimaryButton";
import TextInputField from "@components/TextInputField";
// Hook form
import { useForm, Controller } from "react-hook-form";
// Styles
import "./styles/styles.scss";
// Utils
import { status as reqStatus } from "@utils/constants";
import { productListStatusCode, productListRequestType } from "../../utils/constants";
import { formSubmitOnEnter } from "@utils/formSubmitOnEnter";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { productListRequest } from "../../../ProductList/store/slice";
// Hooks
import { useToast } from "@hooks/useToast";

interface IProps {
   isOpen: boolean;
   handleToggler(newValue?: boolean): void;
}

type TFormData = {
   listNewName: string;
};

const SaveProductListModal = ({ isOpen = false, handleToggler = () => null }: IProps) => {
   // Hooks
   const { pushToast } = useToast();
   // Redux methods
   const { code, error } = useSelector((state: RootState) => state.productList);
   const dispatch = useDispatch();
   // Hook form methods
   const { control, handleSubmit } = useForm<TFormData>({
      defaultValues: {
         listNewName: "",
      },
   });

   const handleSaveList = (data: { listNewName?: string }): void => {
      dispatch(
         productListRequest({
            code: productListStatusCode.SAVE_PRODUCT_LIST_PENDING,
            type: productListRequestType.SAVE_PRODUCTS_LIST,
            listName: data.listNewName,
         })
      );
   };

   useEffect(() => {
      if (code === productListStatusCode.SAVE_PRODUCT_LIST_SUCCESS) {
         pushToast({
            message: "Success",
            type: "success",
            state: "solid",
         });
         handleToggler();
      } else if (code === productListStatusCode.SAVE_PRODUCT_LIST_FAILURE) {
         pushToast({
            message: error?.message,
            type: "error",
            state: "solid",
         });
      }
   }, [code]);

   return (
      <Modal
         withCloseButton={true}
         className="save-product-list-modal"
         handleToggler={handleToggler}
         isOpen={isOpen}
      >
         <div className="save-product-list-modal__wrapper">
            <p className="h4 save-product-list-modal__header">Save List</p>
            <p className="p-14-med save-product-list-modal__info">
               Please give the new list a name.
            </p>
            <form
               className="save-product-list-modal__form"
               {...formSubmitOnEnter(handleSubmit(handleSaveList))}
            >
               <Controller
                  name="listNewName"
                  control={control}
                  render={({ field }) => (
                     <TextInputField
                        label="LIST NAME"
                        placeholder="Name"
                        size="fluid"
                        isDisabled={status === reqStatus.PENDING}
                        {...field}
                     />
                  )}
               />
               <PrimaryButton
                  isDisabled={status === reqStatus.PENDING}
                  isLoading={status === reqStatus.PENDING}
                  handleOnClick={handleSubmit(handleSaveList)}
                  className="save-product-list-modal__form__submit"
               >
                  <PrimaryButton.Content>Save</PrimaryButton.Content>
               </PrimaryButton>
            </form>
         </div>
      </Modal>
   );
};

export default React.memo(SaveProductListModal);
