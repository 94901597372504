export const accountManagementRequestType = {
   GET_USER: "GET_USER",
   GET_USER_LOGS: "GET_USER_LOGS",
   EDIT_USER: "EDIT_USER",
   GET_USERS: "GET_USERS",
   REMOVE_USER: "REMOVE_USER",
   CREATE_USER: "CREATE_USER",
   RESET_PASSWORD: "RESET_PASSWORD",
   GET_BRANCH: "GET_BRANCH",
};

export const stateType = {
   users: "users",
   user: "user",
   createUser: "createUser",
   downloadUserLogs: "downloadUserLogs",
   editUser: "editUser",
   removeUser: "removeUser",
   resetPassword: "resetPassword",
   branch: "branch",
} as const;
