import React, { useContext } from "react";
// Styles
import "./styles/styles.scss";
// Context
import { Context } from "../../context";
// Utils
import { capitalizedFirstLetter } from "@utils/capitalizedFirstLetter";

interface IProps {
   [x: string]: unknown;
}

const Input = React.forwardRef<HTMLInputElement, IProps>(({ ...rest }, ref) => {
   const { label, name, showPassword, type, placeholder, isDisabled } =
      useContext(Context);

   return (
      <fieldset className="text-input__fieldset">
         <legend className="text-input__input-label p-16-med">
            {capitalizedFirstLetter(label || "")}
         </legend>
         <input
            {...rest}
            ref={ref}
            name={name}
            disabled={isDisabled}
            className="text-input__input p-16-reg"
            type={showPassword ? "text" : type}
            placeholder={placeholder}
         />
      </fieldset>
   );
});

Input.displayName = "Input";
export default React.memo(Input);
