export const productListCacheKey = {
   GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
};

export const productListRequestType = {
   GET_LIST_PRODUCTS: "GET_LIST_PRODUCTS",
   DELETE_LIST_PRODUCTS: "DELETE_LIST_PRODUCTS",
   PUSH_PRODUCTS_INTO_LIST: "PUSH_PRODUCTS_INTO_LIST",
   SAVE_PRODUCTS_LIST: "SAVE_PRODUCTS_LIST",
   REMOVE_PRODUCT_FROM_LIST: "REMOVE_PRODUCT_FROM_LIST",
} as const;

export type TReqType =
   (typeof productListRequestType)[keyof typeof productListRequestType];

export const productListStatusCode = {
   // Get product
   GET_LIST_PRODUCT_PENDING: "GET_LIST_PRODUCT_PENDING",
   GET_LIST_PRODUCT_SUCCESS: "GET_LIST_PRODUCT_SUCCESS",
   GET_LIST_PRODUCT_FAILURE: "GET_LIST_PRODUCT_FAILURE",
   // Delete product
   DELETE_LIST_PRODUCT_PENDING: "DELETE_LIST_PRODUCT_PENDING",
   DELETE_LIST_PRODUCT_SUCCESS: "DELETE_LIST_PRODUCT_SUCCESS",
   DELETE_LIST_PRODUCT_FAILURE: "DELETE_LIST_PRODUCT_FAILURE",
   // Remove product
   REMOVE_PRODUCT_FROM_LIST_PENDING: "REMOVE_PRODUCT_FROM_LIST_PENDING",
   REMOVE_PRODUCT_FROM_LIST_SUCCESS: "REMOVE_PRODUCT_FROM_LIST_SUCCESS",
   REMOVE_PRODUCT_FROM_LIST_FAILURE: "REMOVE_PRODUCT_FROM_LIST_FAILURE",
   // Push product
   PUSH_PRODUCT_INTO_LIST_PENDING: "PUSH_PRODUCT_INTO_LIST_PENDING",
   PUSH_PRODUCT_INTO_LIST_SUCCESS: "PUSH_PRODUCT_INTO_LIST_SUCCESS",
   PUSH_PRODUCT_INTO_LIST_FAILURE: "PUSH_PRODUCT_INTO_LIST_FAILURE",
   // Save list
   SAVE_PRODUCT_LIST_PENDING: "SAVE_PRODUCT_LIST_PENDING",
   SAVE_PRODUCT_LIST_SUCCESS: "SAVE_PRODUCT_LIST_SUCCESS",
   SAVE_PRODUCT_LIST_FAILURE: "SAVE_PRODUCT_LIST_FAILURE",
} as const;

export type TStatusCode =
   (typeof productListStatusCode)[keyof typeof productListStatusCode];
