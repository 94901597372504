import React, { useContext, useMemo } from "react";
// Styles
import "./styles/styles.scss";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { searchResultRequest } from "@features/Search/store/slice";
// Types
import { RootState } from "@redux/rootReducer";
import { TCurrency } from "@features/Search/store/slice/types";
// Context
import { Context } from "@layouts/NavigationBar/context";
// Utils
import {
   searchResultRequestType as reqType,
   stateType,
} from "@features/Search/utils/constants";

const CurrencyList = () => {
   // Context methods
   const { handleToggleMenu } = useContext(Context);
   // Redux methods
   const dispatch = useDispatch();
   const { currency } = useSelector((state: RootState) => state.searchResult);
   // Others
   const currencies = useMemo<TCurrency[]>(() => {
      return [
         {
            currency: "USD",
            locale: "en-US",
            symbol: "$",
         },
         {
            currency: "EUR",
            locale: "en-US",
            symbol: "€",
         },
         {
            currency: "GBP",
            locale: "en-GB",
            symbol: "£",
         },
         {
            currency: "MAD",
            locale: "ar-MA",
            symbol: "د.م",
         },
      ];
   }, []);

   const updateCurrency = (currency: TCurrency) => {
      dispatch(
         searchResultRequest({
            type: reqType.UPDATE_CURRENCY,
            stateType: stateType.currency,
            currency,
         })
      );
      handleToggleMenu?.("CURRENCY");
   };

   return (
      <div className="currency-list">
         {currencies.map((currencyItem) => (
            <p
               className={`
                currency-list__currency 
                currency-list__currency--${
                   currencyItem.currency === currency.items?.currency ? "active" : ""
                }
                p-16-med
                `}
               onClick={() => updateCurrency(currencyItem)}
               key={currencyItem.currency}
            >
               {currencyItem.symbol} - {currencyItem.currency}
            </p>
         ))}
      </div>
   );
};

export default React.memo(CurrencyList);
