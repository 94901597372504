import { ReactNode, Fragment, memo, useLayoutEffect } from "react";
// Utils
import { history } from "@utils/history";

interface IProps {
   unwantedQueries: string[];
   children: ReactNode;
}

/**
 * The purpose of this component is to remove the unwanted queries when user
 * clicks the back or forward button.
 *
 * You can pass an arry of unwanted queries so that when the user clicks the back or forward button
 * it will automatically replace the url without the unwanted queries.
 *
 * Note: for this to work properly you must use the replace feature on useNavigate or useSearchParams!
 *
 * Sample I have a modal and I do not want it to be pushed into the history stack I will do.
 *
 * ------------------------
 * searchParams.delete("modal");
 * setParams(searchParams, {replace: true, state: "close"}) ->
 * ------------------------
 * as you can see we are using the replace feature and as you noticed we added a state called "close",
 * it is necessary for the popstateQueryRemover component so that it will not block the update when you
 * delete the query.
 * ------------------------
 * Same with how you will open the modal you also need to use the replace feature
 *
 * searchParams.set("modal");
 * setParams(searchParams, {replace: true}) -> We do not need to add a state because it is only required
 * for closing the modal without getting blocked by the history.block that is inside the component.
 *
 * !important -> Always use the replace feature on the methods that is mentioned above, it might cause some
 * weird behaviour and issue if you just pass any queries in the unwantedQueries array without using the replace feature.
 */

const PopStateQueryRemover = ({ unwantedQueries = [], children }: IProps) => {
   let blocker: () => void;
   const urlWithoutUnwantedQueries = (): URL => {
      const url = new URL(window.location.href);
      // Iterate and remove unwanted queries
      unwantedQueries.forEach((query) => url.searchParams.delete(query));
      return url;
   };

   useLayoutEffect(() => {
      const historyListener = history.listen((listen) => {
         if (listen?.location.search.includes("modal")) {
            blocker = history.block((block) => {
               blocker();
               if (
                  (listen?.action === "REPLACE" && block?.action === "POP") ||
                  block?.action === "PUSH"
               ) {
                  history.replace({
                     search: urlWithoutUnwantedQueries()?.searchParams?.toString(),
                  });
               }

               if (block?.action === "PUSH" || block?.location.state === "close") {
                  block?.retry();
               }
            });
         }
      });

      return () => {
         historyListener();
      };
   }, []);

   useLayoutEffect(() => {
      const navigationType = window.performance.navigation.type;
      // when navigation type is 2 it means the page is being loaded when user clicks the back or forward button
      if (navigationType === 2) {
         /**
          * We are going to update the current url with the new url without the unwanted queries
          * and we also wrapped it with setTimeout so that it will get called after all the codes has been run.
          */
         setTimeout(() => {
            history.replace({
               search: urlWithoutUnwantedQueries()?.searchParams?.toString(),
            });
         }, 0);
      }

      window.addEventListener("beforeunload", () => {
         blocker?.();
      });
   }, []);

   return <Fragment>{children}</Fragment>;
};

export default memo(PopStateQueryRemover);
