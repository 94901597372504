export const matchesRequestType = {
   GET_MATCHES: "GET_MATCHES",
   GET_MATCHES_OLD: "GET_MATCHES_OLD",
   GET_MATCHES_BRANCHES: "GET_MATCHES_BRANCHES",
   GET_MATCHES_RANGES: "GET_MATCHES_RANGES",
   DOWNLOAD_MATCHES: "DOWNLOAD_MATCHES",
} as const;

export type TRequestType = (typeof matchesRequestType)[keyof typeof matchesRequestType];

export const stateType = {
   matches: "matches",
   branches: "branches",
   ranges: "ranges",
   download: "download",
} as const;

export type TStateType = (typeof stateType)[keyof typeof stateType];
