import { useContext } from "react";
// Context
import { TToastContextValues, ToastContext } from "@components/Toast/context";

/**
 * pushToast -> function that will let you push a toast into the ui, this function accepts
 * an object with this specific keys and values that is stated below.
 *
 * - message => any message that you want to show in the ui.
 * - type => one of success | warning | error | info.
 * - state => one of solid | outline.
 *
 * removeToast -> function that will let you remove a toast from the ui by just passing a toast id.
 *
 * listOfToast -> will return you the array of toast.
 */

export const useToast = (): TToastContextValues => {
   const { pushToast, removeToast, listOfToast } = useContext(ToastContext);

   return { pushToast, removeToast, listOfToast };
};
