import { AxiosError, AxiosResponse } from "axios";
// axios
import { axios } from "@redux/store";
//  Types
import { TBanner, TError } from "../../slice/types";
import { CallEffect, call } from "redux-saga/effects";
import { TGetBannerResponse } from "./types";
import { TStateType, stateType } from "@features/Banner/utils/constants";

export function* handleGetBanner(): Generator<
   CallEffect<AxiosResponse>,
   {
      item: TBanner;
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: TGetBannerResponse = yield call(() => {
         return axios({
            url: "/banners",
            method: "GET",
         });
      });

      if (res.data.items.length) {
         return {
            item: res.data.items[0],
            stateType: stateType.banner,
         };
      } else {
         return {
            item: {
               id: "",
               type: null,
               content: "",
               createdAt: "",
            },
            stateType: stateType.banner,
         };
      }
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
      };
   }
}
