// Redux
import { authRequest } from "@features/Auth/store/slice";
// Store
import { axiosInstance, store } from "@redux/store";
// Utils
import { authRequestType, authStatusCode } from "@features/Auth/utils/constants";
// Types
import { AxiosError, InternalAxiosRequestConfig } from "axios";

export default () => {
   // Request's
   axiosInstance.interceptors.request.use((request) => {
      // Get the auth state
      const authState = store.getState().auth;
      // Then update the authrorization header
      request.headers["Authorization"] = `Bearer ${authState?.data?.idToken?.jwtToken}`;
      // Finally return the request
      return request;
   });

   // Response's
   let isTokenRefreshing = false;
   let arrayOfPendingResponse: (() => void)[] = [];
   axiosInstance.interceptors.response.use(undefined, async (error: AxiosError) => {
      let authState = store.getState().auth;
      // In here we will check if the response is 401 or unauthorized
      if (error.response?.status === 401 && authState.user.isSignedIn) {
         // Then we will also check if "isTokenRefreshing" is false
         if (!isTokenRefreshing) {
            /**
             * We set the "isTokenRefreshing" to true so that the other
             * unauthorized response will not invoke the codes in the bottom
             */
            isTokenRefreshing = true;
            // Then we will dispatch the auth request and passed "shoudUpdateToken" so that it will fetch new token
            store.dispatch(
               authRequest({
                  type: authRequestType.GET_SESSION,
                  code: authStatusCode.GET_SESSION_PENDING,
                  shouldUpdateToken: true,
               })
            );
            // Then here we will wait for the auth request to be "resolved"
            await new Promise((resolve) => {
               const interval = setInterval(() => {
                  // Update the authState variable
                  authState = store.getState().auth;
                  // Then check if the get session is done
                  if (
                     authState.code !== authStatusCode.GET_SESSION_PENDING &&
                     authState.code !== authStatusCode.GET_SESSION_FAILURE
                  ) {
                     clearInterval(interval);
                     resolve(null);
                  }
               }, 1000);
            });
            try {
               /**
                * Here we are returning the first unauthorized response and re-try the request.
                * we are using try / finally so that even if we return something we will still be able
                * to invoke the codes inside the finally block
                */
               return axiosInstance.request(error.config as InternalAxiosRequestConfig);
            } finally {
               // Here we are invoking all the pending response so that they will be resolved and be re-fetched
               arrayOfPendingResponse.forEach((subs) => subs());
               // Then we emptied the array of pending responses
               arrayOfPendingResponse = [];
               /**
                * And also set the "isTokenRefreshing" to false so that the next unauthorized request
                * response will go through the process again
                */
               isTokenRefreshing = false;
            }
         } else {
            /**
             * If we are currently refreshing the codes we want the incomming unauthorized
             * response to be push to our "arrayOfPendingResponses" array
             */
            return new Promise((resolve) => {
               arrayOfPendingResponse.push(() => {
                  resolve(
                     axiosInstance.request(error.config as InternalAxiosRequestConfig)
                  );
               });
            });
         }
      } else {
         // If it is not 401 then we will just return it as rejected error
         return Promise.reject(error);
      }
   });
};
