import React, { ReactNode } from "react";

interface IProps {
   children: ReactNode;
   className?: string;
}

const Footer = ({ children, className = "" }: IProps) => {
   return <div className={`card__footer ${className}`}>{children}</div>;
};

export default React.memo(Footer);
