import { ReactNode } from "react";
// Components
import Header from "./subComponents/Header";
import Content from "./subComponents/Content";
import Footer from "./subComponents/Footer";
// Styles
import "./styles/main.scss";

export interface IProps {
   /**
    * - <Box.Header /> -> Wrap your header with this.
    * - <Box.Content /> -> Wrap your content with this.
    * - <Box.Footer /> -> Wrap your footer with this.
    */
   children?: ReactNode;
   /**
    * To reuse and to customize the box on other component you can pass a className into this component.
    */
   className?: string;
   [x: string]: unknown;
}

const Box = ({ children, className = "", ...rest }: IProps) => {
   return (
      <div data-testid="box" className={`box ${className}`} {...rest}>
         {children}
      </div>
   );
};

Box.Header = Header;
Box.Content = Content;
Box.Footer = Footer;

export default Box;
