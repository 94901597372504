import React, { useContext } from "react";
// Router
import { Link } from "react-router-dom";
// Styles
import "./styles/styles.scss";
// Icons
import LogOutIcon from "@icons/LogOutIcon.svg";
// Components
import Card from "@components/Card";
// Context
import { Context } from "../../../../context";
// Redux
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";

const DropDown = () => {
   // Redux methods
   const { user } = useSelector((state: RootState) => state.auth);
   // Context
   const { handleSignOut } = useContext(Context);

   return (
      <Card className="dropdown">
         {user?.isAdmin && (
            <Link to="/account-management" className="dropdown__link p-16-med">
               Account management
            </Link>
         )}
         <p
            onClick={() => handleSignOut && handleSignOut()}
            className="dropdown__link p-16-med"
         >
            Logout
            <LogOutIcon className="dropdown__link__icon" />
         </p>
      </Card>
   );
};

export default React.memo(DropDown);
