import React, { useContext } from "react";
// Icons
import UserIcon from "@icons/UserIcon.svg";
import CaretDownIcon from "@icons/CaretDownIcon.svg";
// Components
import Dropdown from "./subComponents/Dropdown";
// Styles
import "./styles/styles.scss";
// Context
import { Context } from "../../context";
const UserMenu: React.FC = () => {
   const { activeMenu, handleToggleMenu } = useContext(Context);

   return (
      <div className="user-menu">
         <div
            className={`
        		user-menu__toggler
        		user-menu__toggler--${activeMenu === "USER" ? "active" : ""}
      			`}
            onClick={() => handleToggleMenu?.("USER")}
         >
            <UserIcon className="user-menu__user-icon" />
            <CaretDownIcon className="user-menu__toggler__icon" />
         </div>
         {activeMenu === "USER" && <Dropdown />}
      </div>
   );
};

export default React.memo(UserMenu);
